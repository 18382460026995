import { makeRequestGet, makeRequestPost } from "../api";

export function getSaltAdmin(dados) {
  return makeRequestPost("auth/get-salt", dados);
}

export function getSaltCommercial(dados) {
  return makeRequestPost("auth/get-salt-commercial", dados);
}

export function getSaltManager(dados) {
  return makeRequestPost("auth/get-salt-manager", dados);
}

export function getSaltOwner(dados) {
  return makeRequestPost("auth/get-salt-owner", dados);
}

export function getSaltPartner(dados) {
  return makeRequestPost("auth/get-salt-partner", dados);
}

export function getSaltPromoter(dados) {
  return makeRequestPost("auth/get-salt-promoter", dados);
}

export function loginAdmin(dados) {
  return makeRequestPost("auth/generate-token-admin", dados);
}

export function loginCommercial(dados) {
  return makeRequestPost("auth/generate-token-commercial", dados);
}

export function loginManager(dados) {
  return makeRequestPost("auth/generate-token-manager", dados);
}

export function loginOwner(dados) {
  return makeRequestPost("auth/generate-token-owner", dados);
}

export function loginPartner(dados) {
  return makeRequestPost("auth/generate-token-partner", dados);
}

export function loginPromoter(dados) {
  return makeRequestPost("auth/generate-token-promoter", dados);
}

export function sendPasswordResetEmail(dados) {
  return makeRequestPost("send-password-reset-email", dados);
}

export async function addUser(dados) {
  return makeRequestPost(`auth/users`, dados);
}

export async function checkEmail(dados) {
  return makeRequestPost(`auth/check-registration`, dados);
}

export function getSaltUser(dados) {
  return makeRequestPost("auth/user/get-salt", dados);
}

export function loginUser(dados) {
  return makeRequestPost("auth/generate-token", dados);
}

export function checkRegistration(dados) {
  return makeRequestPost("auth/check-registration", dados);
}

export function verifyEmail(dados) {
  return makeRequestPost("auth/verify-email", dados);
}
