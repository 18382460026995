import { useNavigate } from "react-router";

export function handleResponse(response) {
  if (response.data) {
    return { success: true, data: response.data, status: response.status };
  }

  return { success: true, data: response.data };
}
export function handleError(error) {
  if (error.response) {
    return {
      success: false,
      error: error.response.data.error,
      status: error.response.status,
    };
  }

  return {
    success: false,
    error: error.response.data.error,
    status: error.response.status,
  };
}
