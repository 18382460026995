import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import client from "../../images/example/client.png";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import HeaderClient from "../../components/headerClient";
import NavClient from "../../components/navClient";

function ClientPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/clientsadmin");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = ["Teatro", "Cinema", "Futebol"];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const [selectedGender, setSelectedGender] = useState("male");

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const [selectedCountry, setSelectedCountry] = useState("pt");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const today = dayjs();

  const [selectedDate, setSelectedDate] = useState(today);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [value, setValue] = React.useState("+351999999999");

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [], // optional,
      excludedCountryies: [], // optional
      continents: [], // optional
    }); // true | false
  };

  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  return (
    <div className="background">
      <HeaderClient />
      <NavClient />
      <div className="topLeftBackSmall">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("yourdata")}</a>
        <div className="whiteInfoCardSmall">
          <img className="imgClientCard" src={client} />
          <div className="mainInfoClient">
            <a className="infoClientName">Duarte Rafael Silva</a>
            <a className="infoClientText">Braga</a>
            <a className="infoClientText" style={{ marginTop: "5%" }}>
              rafael.silva@tectank.pt
            </a>
            <a className="infoClientText">+351 999 999 999</a>
          </div>
          <div className="mainInfoClient">
            <a className="infoClientTitleCard">{t("stations")}</a>
            <div className="row" style={{ marginTop: 5 }}>
              {trainStations.map((station) => (
                <div className={"infoClientGreenCard"}>
                  <a className={"infoClientGreenCardText"}>{station}</a>
                </div>
              ))}
            </div>
            <a className="infoClientTitleCard" style={{ marginTop: "5%" }}>
              {t("preferences")}
            </a>
            <div className="row" style={{ marginTop: 5 }}>
              {preferences.map((preference) => (
                <div className={"infoClientGreenCard"}>
                  <a className={"infoClientGreenCardText"}>{preference}</a>
                </div>
              ))}
            </div>
          </div>
          <div className="infoClientTopRightSmall">
            <a className="infoIdText">id:03</a>
          </div>
         
        </div>
        <div className="whiteEditInfoCardSmall">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2} padding={2}>
              {/* Primeira Coluna */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("firstname")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("firstname")}
                  value={"Duarte Rafael"}
                />
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("lastname")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("lastname")}
                  value="Silva"
                />
                <div style={{ marginTop: "20px" }}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18next?.language}
                  >
                    <DatePicker
                      openTo="day"
                      variant="standard"
                      label={t("birthdate")}
                      views={["day", "month", "year"]}
                      disableFuture
                      value={selectedDate}
                      format="DD/MM/YYYY"
                      onChange={(e) => {
                        setSelectedDate(e);
                      }}
                      disabled={edicao}
                      slotProps={{
                        tabs: {
                          hidden: false,
                        },

                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },

                        desktopPaper: {
                          sx: {
                            borderRadius: "8px",
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            ".MuiPickersMonth-monthButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },
                      }}
                      sx={{
                        width: "60%",

                        "& .MuiInputBase-input.Mui-disabled": {
                          backgroundColor: "white",
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#44b887 !important", // Cor verde para o dia selecionado
                          color: "white", // Cor do texto do dia selecionado
                        },
                        "& .MuiPickersCalendar-root": {
                          borderRadius: "8px !important", // Raio da borda do calendário
                        },

                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",

                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                          position: "absolute",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#0F111199 !important",
                          textAlign: "right",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "15px",
                          borderRadius: "8px",
                          background: !edicao ? "#0f11110d" : "white",
                          borderColor: "transparent !important",
                          width: "100%",
                          height: "34.88px",
                          padding: "16.5px 10px",
                        },
                        "& .MuiInputLabel-root.Mui-disabled ": {
                          color: "#044535 !important",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline ": {
                          border: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "16.5px 0px",
                          width: "100%",
                        },
                      }}
                    ></DatePicker>
                  </LocalizationProvider>
                </div>
                <TextField
                  fullWidth
                  disabled={edicao}
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("gender")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("gender")}
                  value={selectedGender}
                  onChange={handleGenderChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="male">{t("male")}</MenuItem>
                  <MenuItem value="female">{t("female")}</MenuItem>
                  <MenuItem value="notidentified">
                    {t("notidentified")}
                  </MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("postalcode")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("postalcode")}
                  value={"4900-999 Viana do Castelo"}
                />
              </Grid>
              {/* Segunda Coluna */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("district")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("district")}
                  value={"Viana do Castelo"}
                />
                <TextField
                  fullWidth
                  disabled={edicao}
                  select
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...inputPropsStyle,
                      textAlign: "left",
                      borderRadius: "8px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("country")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("gender")}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value="pt">{t("portugal")}</MenuItem>
                  <MenuItem value="eng">{t("england")}</MenuItem>
                  <MenuItem value="sp">{t("spain")}</MenuItem>
                  <MenuItem value="fr">{t("france")}</MenuItem>
                  <MenuItem value="ger">{t("germany")}</MenuItem>
                  <MenuItem value="it">{t("italy")}</MenuItem>
                  <MenuItem value="hol">{t("netherlands")}</MenuItem>
                  <MenuItem value="sw">{t("sweden")}</MenuItem>
                  <MenuItem value="swi">{t("switzerland")}</MenuItem>
                  <MenuItem value="bel">{t("belgium")}</MenuItem>
                  <MenuItem value="nor">{t("norway")}</MenuItem>
                  <MenuItem value="den">{t("denmark")}</MenuItem>
                  <MenuItem value="fin">{t("finland")}</MenuItem>
                  <MenuItem value="gr">{t("greece")}</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("occupation")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("occupation")}
                  value={"Designer"}
                />

                <MuiTelInput
                  disabled={edicao}
                  fullWidth
                  defaultCountry="PT"
                  onChange={handleChange}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  label={t("phone")}
                  value={value}
                  margin="normal"
                />

                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("email")}
                  variant="standard"
                  margin="normal"
                  placeholder="example@email.com"
                  value={"rafael.silva@tectank.pt"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} padding={2}>
              {/* Primeira Coluna */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("stations")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("stations")}
                  value={trainStations}
                />

                <TextField
                  fullWidth
                  disabled
                  multiline
                  rows={3}
                  InputProps={{
                    disableUnderline: true,
                    style: inputTrainStyle,
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("lasttrips")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("lasttrips")}
                  value={`Barcelos-Braga\nBraga-Viana do Castelo\nPorto-Lisboa`}
                />
              </Grid>
              {/* Segunda Coluna */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("preferences")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("preferences")}
                  value={preferences}
                />
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                setEdicao(!edicao);
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button className="clientInfoCancelButton"> {t("cancel")}</button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientPage;
