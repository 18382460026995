import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import toast from "react-hot-toast";
import bcrypt from "bcryptjs";
import { resetNewPassword } from "../../api/requests/Emails";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  function goBack() {
    navigate("/");
  }

  const handlePwd2 = (event) => {
    setPassword2(event.target.value);
  };
  const handlePwd = (event) => {
    setPassword(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  async function resetPassword() {
    if (password && password2) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!regex.test(password)) {
        toast.error(t("passwordError"));
      } else {
        if (password == password2) {
          const salt = await bcrypt.genSalt(10);
          const hashedPassword = await bcrypt.hash(password, salt);
          resetNewPassword({
            token: token,
            salt: salt,
            newPassword: hashedPassword,
          })
            .then((res) => {
              if (res.status == 200 && res.success) {
                toast.success(res.data.message);
                navigate("/");
              } else {
                toast.error(res.error);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        } else {
          toast.error(t("passwordError2"));
        }
      }
    } else {
      toast.error(t("passwordError3"));
    }
  }

  return (
    <div className="background">
      <div className="backgroundImage">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="title">{t("sameplace")}</p>
        </div>
      </div>
      <div className="bottomcenter">
        <div className="topleftrel">
          <IconButton onClick={goBack}>
            <ArrowBackIcon size={"small"} />
          </IconButton>
        </div>
        <div className="input-container">
          <input
            className={`input ${password && "has-text"}`}
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={handlePwd}
            value={password}
            required
          />
          <label htmlFor="password" className={!password && "visible"}>
            {t("password")}
          </label>
          <button className="toggle-password" onClick={toggleShowPassword}>
            {showPassword ? (
              <Visibility size={"small"} />
            ) : (
              <VisibilityOff size={"small"} />
            )}
          </button>
        </div>

        <div className="input-container">
          <input
            className={`input ${password2 && "has-text"}`}
            type={showPassword2 ? "text" : "password"}
            id="password2"
            onChange={handlePwd2}
            value={password2}
            required
          />
          <label htmlFor="password" className={!password2 && "visible"}>
            {t("confirmpassword")}
          </label>
          <button className="toggle-password" onClick={toggleShowPassword2}>
            {showPassword2 ? (
              <Visibility size={"small"} />
            ) : (
              <VisibilityOff size={"small"} />
            )}
          </button>
        </div>

        <button
          className="greenbtn"
          onClick={() => {
            resetPassword();
          }}
        >
          {" "}
          {t("guardar")}
        </button>
        {/* <Link
            className="textlinklogin2"
            to={"/askresetpasswordclient"}
          >
            {t("recuperarPalavraPasse")}
          </Link> */}
      </div>
    </div>
  );
}

export default ResetPassword;
