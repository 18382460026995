import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
  Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import dayjs from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/mmachado.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
  isEqual,
  startOfDay,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getEvent, putEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import {
  deleteEquipment,
  getEquipment,
  getEquipments,
  putEquipment,
} from "../../api/requests/Equipment.js";
import Avatar from "@mui/material/Avatar";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { getManagers } from "../../api/requests/Managers.js";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";
import NavManager from "../../components/navManager.js";

function EquipmentPageManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();

  const { equipmentId } = location.state || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/equipmentsmanager");
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const handleAllowRegisterChange = (equipment) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (equipment) => {
    setAudience(!audience);
  };

  const handlePromoterChange = (equipment) => {
    setPromoterId(equipment.target.value);
  };

  const handleCouncilChange = (equipment) => {
    setCouncilId(equipment.target.value);
  };

  const handleManagerChange = (equipment) => {
    setManagerId(equipment.target.value);
  };

  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [booking, setBooking] = useState(true);
  const [cancel, setCancel] = useState(false);
  const [district, setDistrict] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  const [type, setType] = useState([]);

  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const maxLength = 1000;
  const [modalitiesSelected, setModalitiesSelected] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [freeAccess, setFreeAccess] = useState(null);
  const [priceUrl, setPriceUrl] = useState("");
  const [owner, setOwner] = useState("");
  const [owners, setOwners] = useState([]);
  const [promoter, setPromoter] = useState("");
  const [manager, setManager] = useState("");
  const [equipmentUrl, setEquipmentUrl] = useState("");
  const [free, setFree] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [promoterId, setPromoterId] = useState("");
  const [managerId, setManagerId] = useState("");
  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(Date);
  const [modality, setModality] = useState("0");
  const [modalities, setModalities] = useState([]);
  const [tourLink, setTourLink] = useState("");
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [loadingDistricts, setLoadingDistricts] = useState(true);
  const [loadingPromoters, setLoadingPromoters] = useState(true);
  const [loadingCouncils, setLoadingCouncils] = useState(true);
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [photo, setPhoto] = useState("");
  const [avatarData, setAvatarData] = useState(null);
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [urlEquipment, setUrlEquipment] = useState("");
  const [status, setStatus] = useState(0);
  const [reason, setReason] = useState("");

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleChangeType = (event, newValue) => {
    setTypeId(newValue);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };
  const handleModalitiesChange = (event, newValue) => {
    if (modalities.length > 5) {
      toast.error("modalitiesLimit");
      return;
    }
    setModalities(newValue);
  };
  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    // if (reason) {
    deleteEquipment(equipmentId, { reason })
      .then((res) => {
        if (res.success && res.status === 200) {
          navigate("/equipmentsmanager");
          toast.success(t("deleted"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    // } else {
    //   toast.error(t("reasonError"));
    // }
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  const handleSuspend = () => {
    handleMenuClose();
    setOpenDialog2(true);
  };

  const handleActivate = () => {
    handleMenuClose();
    setOpenDialog3(true);
  };

  const [preview, setPreview] = useState(feira);
  const [preview1, setPreview1] = useState(feira);
  const [preview2, setPreview2] = useState(feira);
  const [preview3, setPreview3] = useState(feira);
  const [preview4, setPreview4] = useState(feira);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };

    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  useEffect(() => {
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id === 1) {
              sports.push(element);
            } else if (element.type_id === 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error(t("500"));
      })
      .finally(() => {
        setLoadingActivities(false);
      });

    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      })
      .finally(() => {
        setLoadingDistricts(false);
      });
    getManagers()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setManagers(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      })
      .finally(() => {
        setLoadingPromoters(false);
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      })
      .finally(() => {
        setLoadingCouncils(false);
      });

    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      })
      .finally(() => {
        setLoadingTypes(false);
      });
  }, []);

  useEffect(() => {
    if (
      !loadingDistricts &&
      !loadingActivities &&
      !loadingPromoters &&
      !loadingCouncils &&
      !loadingTypes
    ) {
      getEquipment(equipmentId)
        .then((res) => {
          if (res.status === 200 && res.success) {
            let data = res.data;
            setData(data);
            setDataCopy(data);
            setAudienceCapacity(data.capacity);
            setCoordinates(data.coordinates);
            setDescription(data.description);
            setDistrict(data.district[0]);
            setFree(data.free_access);
            setPhoto(data.photo);
            setPreview1(data.gallery[0]);
            setPreview2(data.gallery[1]);
            setPreview3(data.gallery[2]);
            setPreview4(data.gallery[3]);
            setFileUrl(data.schedules[0]);
            setManagerId(data.manager);
            setName(data.name);
            setCouncilId(data.owner);
            setVisible(data.visible);
            setPriceUrl(data.price_url);
            setPromoterId(data.promoter);
            let typeF = [];
            types.forEach((element) => {
              data.type_id.forEach((element2) => {
                if (element.id == element2) {
                  typeF.push(element);
                }
              });
            });
            setTypeId(typeF);
            setTourLink(data.url);
            setEquipmentUrl(data.url_equipment);
            setUrlFacebook(data.url_facebook);
            setUrlInstagram(data.url_instagram);
            setUrlLinkedin(data.url_linkedin);
            setUrlTwitter(data.url_twitter);
            setUrlYoutube(data.url_youtube);
            let mod = data.modalities;
            let final = [];
            mod.forEach((element) => {
              const sportActivity = sportsActivities.find(
                (activity) => activity.id === element
              );

              const culturalActivity = culturalActivities.find(
                (activity) => activity.id === element
              );

              if (sportActivity) {
                final.push(sportActivity);
              } else if (culturalActivity) {
                final.push(culturalActivity);
              }
            });
            setContact(data.contact);
            setModalities(final);
            setModality(final);
            setStatus(data.status);
            setLoading(false);
          } else {
            toast.error(res.error);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);

          toast.error("500");
        });
    }
  }, [
    loadingDistricts,
    loadingActivities,
    loadingPromoters,
    loadingCouncils,
    loadingTypes,
  ]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };

  // const onDropFile = (acceptedFiles) => {
  //   const file = acceptedFiles[0];

  //   // Converte o arquivo para base64
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const base64String = reader.result.split(",")[1];

  //     // Armazena o base64 em vez do arquivo original
  //     setFile(base64String);
  //   };

  //   reader.readAsDataURL(file); // Inicia a leitura do arquivo

  //   // Determine o tipo do arquivo
  //   const fileType = file.type.includes("pdf") ? "pdf" : "image";
  //   // setFileType(fileType);
  // };

  useEffect(() => {
    if (file) {
      // Converte o arquivo para base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setFileUrl(base64String); // Armazena o base64String em fileUrl
      };

      reader.readAsDataURL(file); // Inicia a leitura do arquivo
    }
  }, [file]);

  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };

  function saveData() {
    if (!name) {
      toast.error(t("nameEqValidation"));
      return;
    }
    if (typeId.length == 0) {
      toast.error(t("typeIdEqValidation"));
      return;
    }
    if (!equipmentUrl) {
      toast.error(t("urlEqValidation"));
      return;
    }
    if (!description) {
      toast.error(t("typeIdValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (modalities.length == 0) {
      toast.error(t("modalityValidation"));
      return;
    }
    if (!contact) {
      toast.error(t("contactEqValidation"));
      return;
    }
    if (fileUrl == null || fileUrl.length == 0) {
      toast.error(t("schedulesEqValidation"));
      return;
    }
    if (free == null) {
      toast.error(t("freeAccessEqValidation"));
      return;
    }
    if (councilId == "") {
      toast.error(t("ownerEqValidation"));
      return;
    }
    if (promoterId == "") {
      toast.error(t("promoterEqValidation"));
      return;
    }
    if (managerId == "") {
      toast.error(t("managerEqValidation"));
      return;
    }
    if (tourLink == "") {
      toast.error(t("urlVisits"));
      return;
    }
    if (!audienceCapacity) {
      toast.error(t("audienceCapacityEqValidation"));
      return;
    }

    let typeF = [];
    typeId.forEach((element) => {
      typeF.push(element.id);
    });

    let modalitiesF = [];
    modalities.forEach((element) => {
      modalitiesF.push(element.id);
    });

    putEquipment(equipmentId, {
      type_id: typeF,
      name: name,
      url: tourLink,
      description: description,
      coordinates: coordinates,
      district_id: district.id,
      modalities: modalitiesF,
      contact: contact,
      schedules: fileUrl,
      free_access: free,
      price_url: priceUrl,
      gallery: [preview1, preview2, preview3, preview4],
      photo: photo,
      owner: councilId,
      promoter: promoterId,
      manager: managerId,
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_youtube: urlYoutube,
      url_twitter: urlTwitter,
      url_equipment: equipmentUrl,
      capacity: audienceCapacity,
      status: status,
      visible: visible,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          setEdicao(!edicao);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  function getActivitiesTypes(typeId) {
    let activities = [];

    const ids = typeId.map((item) => item.id);

    if (ids.includes("1")) {
      activities = activities.concat(sportsActivities);
    }

    if (ids.includes("2")) {
      activities = activities.concat(culturalActivities);
    }

    return activities;
  }

  function originData() {
    setAudienceCapacity(dataCopy.capacity);
    setCoordinates(dataCopy.coordinates);
    setDescription(dataCopy.description);
    setDistrict(dataCopy.district[0]);
    setFree(dataCopy.free_access);
    setPhoto(dataCopy.photo);
    setPreview1(dataCopy.gallery[0]);
    setPreview2(dataCopy.gallery[1]);
    setPreview3(dataCopy.gallery[2]);
    setPreview4(dataCopy.gallery[3]);
    setFileUrl(dataCopy.schedules[0]);
    setManagerId(dataCopy.manager);
    setName(dataCopy.name);
    setCouncilId(dataCopy.owner);
    setPriceUrl(dataCopy.price_url);
    setPromoterId(dataCopy.promoter);
    let typeF = [];
    types.forEach((element) => {
      dataCopy.type_id.forEach((element2) => {
        if (element.id == element2) {
          typeF.push(element);
        }
      });
    });
    setTypeId(typeF);
    setTourLink(dataCopy.url);
    setEquipmentUrl(dataCopy.url_equipment);
    setUrlFacebook(dataCopy.url_facebook);
    setUrlInstagram(dataCopy.url_instagram);
    setUrlLinkedin(dataCopy.url_linkedin);
    setUrlTwitter(dataCopy.url_twitter);
    setUrlYoutube(dataCopy.url_youtube);
    let mod = dataCopy.modalities;
    let final = [];
    mod.forEach((element) => {
      const sportActivity = sportsActivities.find(
        (activity) => activity.id === element
      );

      const culturalActivity = culturalActivities.find(
        (activity) => activity.id === element
      );

      if (sportActivity) {
        final.push(sportActivity);
      } else if (culturalActivity) {
        final.push(culturalActivity);
      }
    });
    setContact(dataCopy.contact);
    setModalities(final);
    setModality(final);
  }

  const handleDialogClose2 = () => {
    if (reason) {
      putEquipment(equipmentId, {
        status: 2,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog2(false);
            setStatus(2);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const handleDialogClose3 = () => {
    if (reason) {
      putEquipment(equipmentId, {
        status: 1,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog3(false);
            setStatus(1);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  return (
    <div className="background">
      <HeaderManagerWhite/>
      <NavManager />
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("equipment")}</a>
        <div className="whiteInfoCard">
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={"15%"}
                height={"80%"}
                sx={{ margin: "1%", borderRadius: "0.9375rem" }}
              />
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <Skeleton
                  variant="text"
                  width={"70%"}
                  height={40}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "10px", width: "400px" }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginBottom: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginTop: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <Skeleton
                  className="imgClientCard"
                  variant="rectangular"
                  width={"15%"}
                  height={"80%"}
                />
              ) : edicao ? (
                <div className="profileColumn">
                  <div className="addProfile">
                    <img src={photo} className="addProfile" />
                  </div>
                </div>
              ) : (
                <div className="profileColumn">
                  <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className="addProfile" {...getRootProps()}>
                          <input {...getInputProps()} />
                          {!feira ? (
                            <div className="addProfilePhoto">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(15, 17, 17, 0.60)",
                                }}
                                icon={faPen}
                              />
                            </div>
                          ) : (
                            <div className="addProfileEdit">
                              <img src={photo} className="addProfile" />
                              <div className="overlay">
                                <FontAwesomeIcon
                                  className="hoverIcon"
                                  icon={faPen}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              )}
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <a className="infoClientName">{name}</a>
                <a className="infoEventText">{district[i18next.language]}</a>
                {/* <a className="infoEventText" style={{ marginTop: "4%" }}>
                  {description}
                </a> */}
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "20px", width: "400px", marginLeft: 100 }}
              >
                <a className="infoClientTitleCard">{t("modalities")}</a>

                <div className="row" style={{ marginTop: 5, marginLeft: -10 }}>
                  {loading ? (
                    <>
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={50}
                      />
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={50}
                      />
                      <Skeleton
                        className="infoClientGreenCard"
                        variant="rectangular"
                        width="60px"
                        height={50}
                      />
                    </>
                  ) : (
                    modality.map((modality) => (
                      <div className="infoClientGreenCard" key={modality.id}>
                        <a className="infoClientGreenCardText">
                          {modality[i18next.language]}
                        </a>
                      </div>
                    ))
                  )}
                </div>

                <a className="infoClientTitleCard" style={{ marginTop: "4%" }}>
                  {t("capacity")}
                </a>

                <a className="infoEventText">{audienceCapacity}</a>
              </div>
              {/* <div className="infoClientTopRight">
            <a className="infoIdText">id:03</a>
          </div> */}
              <div className="infoClientBottomRight">
                <a
                  className={edicao ? "activeText" : "activeTextEdit"}
                  onClick={handleMenuOpen}
                >
                  {status == 1 ? t("active") : t("suspended")}
                  {!edicao ? <a className="arrowDown">▼</a> : null}
                </a>
              </div>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  background: "transparent",

                  borderColor: "transparent",
                  marginTop: "25px",
                  width: "214px",
                  height: "auto",
                  borderRadius: "8px !important",
                }}
                MenuListProps={{
                  style: {
                    padding: "8px 0",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "8px !important",
                  },
                }}
              >
                {status === 1 && (
                  <>
                    <MenuItem
                      onClick={handleSuspend}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("suspend")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
                {status === 2 && (
                  <>
                    <MenuItem
                      onClick={handleActivate}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("activate")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
              </Menu>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("deleteEquipment")}</a>
                  </DialogContentText>
                  {/* <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose} className="deleteBtn">
                      {t("delete")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog2}
                onClose={handleDialogClose2}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("suspendEquipment")}</a>
                  </DialogContentText>

                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose2} className="suspendBtn">
                      {t("suspend")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog2(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog3}
                onClose={handleDialogClose3}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("activateEquipment")}</a>
                  </DialogContentText>
                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={handleDialogClose3}
                      className="activateBtn"
                    >
                      {t("activate")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog3(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
        <div className="whiteEditInfoCard">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photosEvent">
                  <div>
                    <a className="photoLabel">{t("photos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("contact")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      value={contact}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("free")}?</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={free}
                      onChange={(e) => {
                        setFree(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("capacity")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      type="number"
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={audienceCapacity}
                      onChange={(e) => {
                        setAudienceCapacity(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("description")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={80}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Box>
                      <TextField
                        fullWidth
                        disabled={edicao}
                        multiline
                        rows={16}
                        InputProps={{
                          disableUnderline: true,
                          style: inputPropsStyle,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        inputProps={{
                          maxLength: maxLength,
                        }}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                        value={description}
                      />
                      {/* <Typography
                        variant="body2"
                        align="right"
                        sx={{
                          color:
                            description.length >= maxLength ? "red" : "#044535",
                        }}
                      >
                        {maxLength - description.length} characters remaining
                      </Typography> */}
                    </Box>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disabled={edicao}
                      options={types}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={Array.isArray(typeId) ? typeId : []}
                      onChange={handleChangeType}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("type")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("modalities")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      disabled={edicao}
                      options={getActivitiesTypes(typeId)}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={Array.isArray(modalities) ? modalities : []}
                      onChange={handleModalitiesChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("modality")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("schedule")}</a>
                  {!edicao && (
                    <Dropzone
                      onDrop={onDropFile}
                      accept="image/*"
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={dropzoneStyle}>
                          <input {...getInputProps()} />
                          <p>{t("selectImg")}</p>
                        </div>
                      )}
                    </Dropzone>
                  )}
                  {fileUrl && (
                    <div>
                      <img src={fileUrl} alt="Preview" style={imageStyle} />
                    </div>
                  )}
                  {/* {file && fileType === "pdf" && (
                    <div>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={fileUrl}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </Worker>
                    </div>
                  )} */}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={edicao}
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("promoter")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={promoterId}
                      onChange={handlePromoterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {promoters.map((promoter) => (
                        <MenuItem key={promoter._id} value={promoter._id}>
                          {promoter.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("manager")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={managerId}
                      onChange={handleManagerChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {managers.map((manager) => (
                        <MenuItem key={manager._id} value={manager._id}>
                          {manager.first_name} {manager.last_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("cityCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={councilId}
                      onChange={handleCouncilChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {cityCouncils.map((council) => (
                        <MenuItem key={council._id} value={council._id}>
                          {council.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></div>
                <a className="photoLabel">{t("district")}</a>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width="60%"
                    height={40}
                    sx={{ borderRadius: "0.9375rem" }}
                  />
                ) : (
                  <Autocomplete
                    disabled={edicao}
                    options={districts}
                    getOptionLabel={(option) =>
                      option[i18next.language] || option.en
                    }
                    value={district}
                    onChange={handleDistrictChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option[i18next.language] || option.en}
                      </li>
                    )}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                      },
                      "& .MuiAutocomplete-listbox": {
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        padding: "0 !important",
                      },
                    }}
                  />
                )}
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                      onChange={(e) => {
                        setCoordinates(e.target.value);
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLink")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEquipmentUrl(e.target.value);
                      }}
                      value={equipmentUrl}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("tourLink")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setTourLink(e.target.value);
                      }}
                      value={tourLink}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentYoutube")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLinkedin")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentX")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                if (edicao) {
                  originData();
                  setEdicao(!edicao);
                } else {
                  saveData();
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button
                className="clientInfoCancelButton"
                onClick={() => {
                  originData();
                  setEdicao(!edicao);
                }}
              >
                {t("cancel")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentPageManager;
