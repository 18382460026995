import { makeRequestGet } from "../api";

export function getCountries() {
  return makeRequestGet("countries");
}

export function getTrainStations() {
  return makeRequestGet("trainstations");
}

export function getActivities() {
  return makeRequestGet("modalities");
}

export function getSubActivities() {
  return makeRequestGet("submodalities");
}

export function getTypes() {
  return makeRequestGet("types");
}

export function getServicesTypes() {
  return makeRequestGet("service-types");
}

export function getGenders() {
  return makeRequestGet("genders");
}

export function getDistricts() {
  return makeRequestGet("districts");
}

export function getMunicipalities() {
  return makeRequestGet("municipalities");
}
