import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavManager from "../../components/navManager";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";

function HelpManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="background">
      <HeaderManagerWhite />
      <NavManager />
      <div className="centerpage">
        <a className="greenTitle">{t("help")}</a>
        <div className="whiteBoard">
          <div className="scrollableContent">
            <p className="helpTitle"> Lorem ipsum dolor sit amet,</p>
            <p className="helpText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              euismod ipsum nec turpis porta, molestie tempor ante vulputate. In
              nec tellus vel neque porttitor tempor sit amet sit amet sapien.
              Pellentesque pharetra, orci ut cursus faucibus, erat orci
              hendrerit elit, ut dictum mi est ut velit. Integer dictum ipsum
              sapien, pharetra rhoncus erat porta quis. Praesent ultrices lectus
              nibh, eget sodales nibh lacinia non. Integer enim ante, fringilla
              id lacus a, accumsan pharetra ipsum. Phasellus nec sem dapibus,
              placerat sapien a, consequat tortor. Vestibulum sollicitudin
              rutrum nisl in scelerisque. Duis consequat volutpat nulla, eu
              eleifend odio pretium a. Mauris lobortis mauris eu pulvinar
              gravida. Nam ac nisi sit amet sapien congue vehicula. Proin auctor
              nunc iaculis faucibus condimentum. Pellentesque habitant morbi
              tristique senectus et netus et malesuada fames ac turpis egestas.
              Aenean et hendrerit nunc, sit amet euismod dui. Vestibulum
              molestie ultricies urna, ut mollis tortor volutpat eu. Proin
              imperdiet tristique dui vitae molestie. Morbi non arcu eget diam
              condimentum fermentum. Vivamus efficitur, quam sit amet venenatis
              lacinia, ipsum eros commodo justo, a suscipit augue felis vitae
              quam. Sed est lectus, semper eu felis vitae, porttitor sagittis
              risus. Integer in tincidunt orci. Suspendisse vel tortor lacus.
              Praesent a consectetur diam. Pellentesque quis pretium eros,
              finibus condimentum metus. Vivamus euismod interdum viverra.
              Vestibulum sed enim enim. Sed viverra nec leo et varius. Sed
              dignissim odio in diam venenatis egestas. Fusce nec est dignissim
              felis malesuada ultricies nec vitae velit. In tincidunt tincidunt
            </p>
            <p className="helpTitle"> Lorem ipsum dolor sit amet,</p>

            <p className="helpText">
              nisi non blandit. Nullam id condimentum nisi. Sed non efficitur
              nisl, sed elementum felis. Sed pellentesque varius nulla eget
              faucibus. Aenean nec tortor sed ex aliquam mattis. Mauris vitae
              risus a sem fermentum bibendum. Etiam vulputate metus eu tortor
              tempor accumsan. Nullam dapibus magna risus, vitae posuere nulla
              hendrerit ac. Curabitur congue, justo a iaculis sodales, urna
              ipsum volutpat erat, nec gravida justo sem in magna. Nullam
              eleifend nisl in tortor cursus, a volutpat elit gravida. Donec eu
              condimentum magna. Cras a risus dapibus quam venenatis hendrerit.
              Sed hendrerit sapien erat, eget molestie quam vulputate eget.
              Integer vitae tempor quam. Integer vel lectus tellus. In tellus
              mi, porttitor eu pulvinar nec, pellentesque eu justo. Sed interdum
              massa massa. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Aliquam euismod ipsum nec turpis porta, molestie tempor ante
              vulputate. In nec tellus vel neque porttitor tempor sit amet sit
              amet sapien. Pellentesque pharetra, orci ut cursus faucibus, erat
              orci hendrerit elit, ut dictum mi est ut velit. Integer dictum
              ipsum sapien, pharetra rhoncus erat porta quis. Praesent ultrices
              lectus nibh, eget sodales nibh lacinia non. Integer enim ante,
              fringilla id lacus a, accumsan pharetra ipsum. Phasellus nec sem
              dapibus, placerat sapien a, consequat tortor. Vestibulum
              sollicitudin rutrum nisl in scelerisque. Duis consequat volutpat
              nulla, eu eleifend odio pretium a. Mauris lobortis mauris eu
              pulvinar gravida. Nam ac nisi sit amet sapien congue vehicula.
              Proin auctor nunc iaculis faucibus condimentum. Pellentesque
              habitant morbi tristique senectus et netus et malesuada fames ac
              turpis egestas. Aenean et hendrerit nunc, sit amet euismod dui.
              Vestibulum molestie ultricies urna, ut mollis tortor volutpat eu.
            </p>
            <p className="helpTitle"> Lorem ipsum dolor sit amet,</p>

            <p className="helpText">
              Proin imperdiet tristique dui vitae molestie. Morbi non arcu eget
              diam condimentum fermentum. Vivamus efficitur, quam sit amet
              venenatis lacinia, ipsum eros commodo justo, a suscipit augue
              felis vitae quam. Sed est lectus, semper eu felis vitae, porttitor
              sagittis risus. Integer in tincidunt orci. Suspendisse vel tortor
              lacus. Praesent a consectetur diam. Pellentesque quis pretium
              eros, finibus condimentum metus. Vivamus euismod interdum viverra.
              Vestibulum sed enim enim. Sed viverra nec leo et varius. Sed
              dignissim odio in diam venenatis egestas. Fusce nec est dignissim
              felis malesuada ultricies nec vitae velit. In tincidunt tincidunt
              nisi non blandit. Nullam id condimentum nisi. Sed non efficitur
              nisl, sed elementum felis. Sed pellentesque varius nulla eget
              faucibus. Aenean nec tortor sed ex aliquam mattis. Mauris vitae
              risus a sem fermentum bibendum. Etiam vulputate metus eu tortor
              tempor accumsan. Nullam dapibus magna risus, vitae posuere nulla
              hendrerit ac. Curabitur congue, justo a iaculis sodales, urna
              ipsum volutpat erat, nec gravida justo sem in magna. Nullam
              eleifend nisl in tortor cursus, a volutpat elit gravida. Donec eu
              condimentum magna. Cras a risus dapibus quam venenatis hendrerit.
              Sed hendrerit sapien erat, eget molestie quam vulputate eget.
              Integer vitae tempor quam. Integer vel lectus tellus. In tellus
              mi, porttitor eu pulvinar nec, pellentesque eu justo. Sed interdum
              massa massa. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Aliquam euismod ipsum nec turpis porta, molestie tempor ante
              vulputate. In nec tellus vel neque porttitor tempor sit amet sit
              amet sapien. Pellentesque pharetra, orci ut cursus faucibus, erat
              orci hendrerit elit, ut dictum mi est ut velit. Integer dictum
              ipsum sapien, pharetra rhoncus erat porta quis. Praesent ultrices
              lectus nibh, eget sodales nibh lacinia non. Integer enim ante,
              fringilla id lacus a, accumsan pharetra ipsum. Phasellus nec sem
              dapibus, placerat sapien a, consequat tortor. Vestibulum
              sollicitudin rutrum nisl in scelerisque. Duis consequat volutpat
              nulla, eu eleifend odio pretium a. Mauris lobortis mauris eu
              pulvinar gravida. Nam ac nisi sit amet sapien congue vehicula.
              Proin auctor nunc iaculis faucibus condimentum. Pellentesque
              habitant morbi tristique senectus et netus et malesuada fames ac
              turpis egestas. Aenean et hendrerit nunc, sit amet euismod dui.
              Vestibulum molestie ultricies urna, ut mollis tortor volutpat eu.
              Proin imperdiet tristique dui vitae molestie. Morbi non arcu eget
              diam condimentum fermentum. Vivamus efficitur, quam sit amet
              venenatis lacinia, ipsum eros commodo justo, a suscipit augue
              felis vitae quam.
            </p>
            <p className="helpTitle"> Lorem ipsum dolor sit amet,</p>

            <p className="helpText">
              Sed est lectus, semper eu felis vitae, porttitor sagittis risus.
              Integer in tincidunt orci. Suspendisse vel tortor lacus. Praesent
              a consectetur diam. Pellentesque quis pretium eros, finibus
              condimentum metus. Vivamus euismod interdum viverra. Vestibulum
              sed enim enim. Sed viverra nec leo et varius. Sed dignissim odio
              in diam venenatis egestas. Fusce nec est dignissim felis malesuada
              ultricies nec vitae velit. In tincidunt tincidunt nisi non
              blandit. Nullam id condimentum nisi. Sed non efficitur nisl, sed
              elementum felis. Sed pellentesque varius nulla eget faucibus.
              Aenean nec tortor sed ex aliquam mattis. Mauris vitae risus a sem
              fermentum bibendum. Etiam vulputate metus eu tortor tempor
              accumsan. Nullam dapibus magna risus, vitae posuere nulla
              hendrerit ac. Curabitur congue, justo a iaculis sodales, urna
              ipsum volutpat erat, nec gravida justo sem in magna. Nullam
              eleifend nisl in tortor cursus, a volutpat elit gravida. Donec eu
              condimentum magna. Cras a risus dapibus quam venenatis hendrerit.
              Sed hendrerit sapien erat, eget molestie quam vulputate eget.
              Integer vitae tempor quam. Integer vel lectus tellus. In tellus
              mi, porttitor eu pulvinar nec, pellentesque eu justo. Sed interdum
              massa massa.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpManager;
