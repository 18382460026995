//Colors used on project
export const Colors = {
  // footer: "#0b1d42",
  //    Define colors use
  darkGreen:"#044535",
  green:"#13BB56",
  black:"#0F1111",
  white:"#EBEBEB",
  shadowColor:"#2C342C"

};
