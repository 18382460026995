import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header";
import NavManager from "../../components/navManager.js";
import dayjs from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/mmachado.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
  isEqual,
  startOfDay,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getEvent, putEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import Avatar from "@mui/material/Avatar";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import HeaderManager from "../../components/headerManager.js";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";

function PromoterPageManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();

  const { eventId } = location.state || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/promotersmanager");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = [
    { id: 1, designation: "atletismo" },
    { id: 2, designation: "futebol" },
  ];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const handleAllowRegisterChange = (equipment) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (equipment) => {
    setAudience(!audience);
  };

  const handlePromoterChange = (equipment) => {
    setPromoterId(equipment.target.value);
  };

  const handleCouncilChange = (equipment) => {
    setCouncilId(equipment.target.value);
  };

  const handleManagerChange = (equipment) => {
    setManagerId(equipment.target.value);
  };

  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState("");
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const [equipmentUrl, setEquipmentUrl] = useState("");
  const [free, setFree] = useState("");
  const [gallery, setGallery] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [VAT, setVAT] = useState("");
  const [postalCode, setPostalCode] = useState("");


  const [obs, setObs] = useState("");
  const [promoterId, setPromoterId] = useState("");
  const [managerId, setManagerId] = useState("");

  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(Date);
  const [modality, setModality] = useState("0");
  const [modalities, setModalities] = useState([]);
  const [councilUrl, setCouncilUrl] = useState("");

  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [video, setVideo] = useState([]);
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [photo, setPhoto] = useState("");
  const [avatarData, setAvatarData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };
  const handleModalitiesChange = (event, newValue) => {
    setModalities(newValue);
  };
  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  const [preview, setPreview] = useState(feira);
  const [preview1, setPreview1] = useState(feira);
  const [preview2, setPreview2] = useState(feira);
  const [preview3, setPreview3] = useState(feira);
  const [preview4, setPreview4] = useState(feira);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setPhoto(base64String);
    };

    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  useEffect(() => {
    const avatar = {
      sx: {
        bgcolor: "#11bb55",
      },
      children: `Teste`,
    };
    setAvatarData(avatar);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };
  // useEffect(() => {
  //   if (file) {
  //     setFileUrl(URL.createObjectURL(file));
  //   }
  //   return () => {
  //     if (fileUrl) {
  //       URL.revokeObjectURL(fileUrl);
  //     }
  //   };
  // }, [file]);
  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };
 

  return (
    <div className="background">
     <HeaderManagerWhite/>
     <NavManager/>
     <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("promoter")}</a>
        <div className="whiteInfoCard">
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={"15%"}
                height={"80%"}
                sx={{ margin: "1%", borderRadius: "0.9375rem" }}
              />
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <Skeleton
                  variant="text"
                  width={"70%"}
                  height={40}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "10px", width: "400px" }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginBottom: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginTop: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <Skeleton
                  className="imgClientCard"
                  variant="rectangular"
                  width={"15%"}
                  height={"80%"}
                />
              ) : photo !== "" ? (
                edicao ? (
                  <div className="profileColumn">
                    <div className="addProfile">
                      <img src={photo} className="addProfile" />
                    </div>
                  </div>
                ) : (
                  <div className="profileColumn">
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addProfile" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!feira ? (
                              <div className="addProfilePhoto">
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "rgba(15, 17, 17, 0.60)",
                                  }}
                                  icon={faPen}
                                />
                              </div>
                            ) : (
                              <div className="addProfileEdit">
                                <img src={photo} className="addProfile" />
                                <div className="overlay">
                                  <FontAwesomeIcon
                                    className="hoverIcon"
                                    icon={faPen}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                )
              ) : avatarData ? (
                <div className="profileColumn">
                  {!photo && !edicao ? (
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addProfile" {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div className="addProfilePhoto">
                              <FontAwesomeIcon
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(15, 17, 17, 0.60)",
                                }}
                                icon={faPen}
                              />
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  ) : (
                    <div className="addProfileEdit">
                      <Avatar className="imgClientCard" sx={avatarData.sx}>
                        {avatarData.children}
                      </Avatar>
                      {/* <div className="overlay">
                  <FontAwesomeIcon
                    className="hoverIcon"
                    icon={faPen}
                  />
                </div> */}
                    </div>
                  )}
                </div>
              ) : (
                <Avatar className="imgClientCard" sx={avatarData.sx}>
                  {avatarData.children}
                </Avatar>
              )}
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <a className="infoClientName">José Ribeiro</a>
                <a className="infoEventText">email@email.com</a>
                <a className="infoEventText">Viana do Castelo</a>
              </div>

              {/* <div className="infoClientTopRight">
            <a className="infoIdText">id:03</a>
          </div> */}
              <div className="infoClientBottomRight">
                <a
                  className={edicao ? "activeText" : "activeTextEdit"}
                  onClick={handleMenuOpen}
                >
                  {/* {status == 1 ? t("active") : t("suspended")} */}
                  {t("active")}
                  {!edicao ? <a className="arrowDown">▼</a> : null}
                </a>
              </div>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  background: "transparent",

                  borderColor: "transparent",
                  marginTop: "25px",
                  width: "214px",
                  height: "auto",
                  borderRadius: "8px !important",
                }}
                MenuListProps={{
                  style: {
                    padding: "8px 0",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "8px !important",
                  },
                }}
              >
                <MenuItem
                  // onClick={handleLogout}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("activate")}
                </MenuItem>
                <MenuItem
                  // onClick={handleLogout}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("suspend")}
                </MenuItem>
                <MenuItem
                  onClick={handleDelete}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: "5px !important",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontFamily: "Montserrat",
                    color: "#044535",
                  }}
                >
                  {t("delete")}
                </MenuItem>
              </Menu>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "50px",
                      marginBottom: "50px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("deleteEvent")}</a>
                  </DialogContentText>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose} className="deleteBtn">
                      {t("delete")}
                    </Button>
                    <Button onClick={confirmDelete} className="cancelBtn">
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
        <div className="whiteEditInfoCard">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photosEvent">
                  <div>
                    <a className="photoLabel">{t("photos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("email")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  )}
                </div>
               
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={typeId}
                      onChange={handleChangeType}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type.id}>
                          {type[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("VAT")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setVAT(e.target.value);
                      }}
                      value={VAT}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("postalCode")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      value={postalCode}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("manager")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={managerId}
                      onChange={handleManagerChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {managers.map((manager) => (
                        <MenuItem key={manager._id} value={manager._id}>
                          {manager.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("cityCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={councilId}
                      onChange={handleCouncilChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {cityCouncils.map((council) => (
                        <MenuItem key={council._id} value={council._id}>
                          {council.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                <a className="photoLabel">{t("district")}</a>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width="60%"
                    height={40}
                    sx={{ borderRadius: "0.9375rem" }}
                  />
                ) : (
                  <Autocomplete
                    disabled={edicao}
                    options={districts}
                    getOptionLabel={(option) =>
                      option[i18next.language] || option.en
                    }
                    value={district}
                    onChange={handleDistrictChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        disabled={edicao}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option[i18next.language] || option.en}
                      </li>
                    )}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                      },
                      "& .MuiAutocomplete-listbox": {
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        padding: "0 !important",
                      },
                    }}
                  />
                )}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={edicao}
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>
              
               
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("urlCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setCouncilUrl(e.target.value);
                      }}
                      value={councilUrl}
                    />
                  )}
                </div>

              
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentYoutube")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLinkedin")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentX")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                if (edicao) {
                  setEdicao(!edicao);
                } else {
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button className="clientInfoCancelButton"> {t("cancel")}</button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoterPageManager;
