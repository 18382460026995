import Cookies from "js-cookie";
import {
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost,
  makeRequestPost2,
  makeRequestPut,
  makeRequestPut2,
} from "../api";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); // Check again after 100ms
      }
    };
    checkToken();
  });
}

export async function getEvents() {
  let token = await getToken();
  return makeRequestGet2(`events/`, token);
}

export async function getEventsGraph(period){
  let token = await getToken();
  return makeRequestPost2(`events/eventsgraph/`,period, token);
}

export async function getEvent(id) {
  let token = await getToken();
  return makeRequestGet2(`events/${id}`, token);
}

export async function postEvent(data) {
  let token = await getToken();
  return makeRequestPost2(`events/`, data, token);
}

export async function putEvent(id, data) {
  let token = await getToken();
  return makeRequestPut2(`events/${id}`, data, token);
}

export async function deleteEvent(id, data) {
  let token = await getToken();
  return makeRequestDelete2(`events/${id}`, data, token);
}
