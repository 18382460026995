import React, { useEffect, useState, useRef } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import { getEvents, getEventsGraph } from "../../api/requests/Event";
import { getActivities, getDistricts } from "../../api/requests/Data";
import { getUserChartData, getUserGrowth } from "../../api/requests/User";
import { getEquipmentsByPeriod } from "../../api/requests/Equipment";
import { getPartnersByPeriod } from "../../api/requests/Partner";
import { getCo2SavingsByPeriod } from "../../api/requests/Trips";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Chart from "chart.js/auto";
import NavManager from "../../components/navManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";

function StatsManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("month");
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const chart = useRef(null);
  const chartRefReservations = useRef(null);
  const chartReservations = useRef(null);
  const chartRefco2 = useRef(null);
  const chartco2 = useRef(null);
  const chartevents = useRef(null);
  const chartRefevents = useRef(null);
  const chartstructures = useRef(null);
  const chartRefstructures = useRef(null);
  const chartpartners = useRef(null);
  const chartRefpartners = useRef(null);
  const [events, setEvents] = useState(true);
  const [activities, setActivities] = useState(true);
  const [districts, setDistricts] = useState(true);
  const [loading, setLoading] = useState(true);
  const userLanguage = navigator.language.split("-")[0];
  const [chartDataReady, setChartDataReady] = useState(false);
  const [totalUsers, setTotalUsers] = useState(-9999);
  const [percentageUsers, setPercentageUsers] = useState(-50);
  const [totalReservations, setTotalReservations] = useState(999);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
      navigate("/loginadmin");
      return;
    }

    const fetchData = async () => {
      try {
        const [eventsRes, districtsRes, activitiesRes] = await Promise.all([
          getEvents(),
          getDistricts(),
          getActivities(),
        ]);

        if (eventsRes.status === 200 && eventsRes.success) {
          setEvents(eventsRes.data);
        } else {
          toast.error(eventsRes.error);
        }

        if (districtsRes.status === 200 && districtsRes.success) {
          setDistricts(districtsRes.data);
        } else {
          toast.error(districtsRes.error);
        }

        if (activitiesRes.status === 200 && activitiesRes.success) {
          setActivities(activitiesRes.data);
        } else {
          toast.error(activitiesRes.error);
        }

        setChartDataReady(true);
      } catch (error) {
        toast.error(t("500"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchDataAndRenderChart = async () => {
      if (!chartRef.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getUserChartData({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          // Se já existe um gráfico, destrói antes de criar um novo
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }

          const ctx = chartRef.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartInstance.current = new Chart(ctx, {
            type: "line",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "User Growth",
                  data: data,
                  borderColor: "white",
                  tension: 0.4,
                  borderCapStyle: "round",
                  pointHoverRadius: 0,
                  pointRadius: 0,
                  fill: {
                    target: "origin",
                    above: "rgba(255, 255, 255, 0.5)",
                  },
                },
              ],
            },
            options: {
              scales: {
                x: { display: false },
                y: { display: false },
              },
              plugins: {
                legend: { display: false },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro de rede:", error);
      }
    };

    if (chartDataReady) {
      fetchDataAndRenderChart();
    }
  }, [chartDataReady, selectedOption]);

  useEffect(() => {
    if (!loading) {
      const ctx = chartRefReservations.current.getContext("2d");

      if (chartReservations.current !== null) {
        chartReservations.current.destroy();
      }

      const redGradient = ctx.createLinearGradient(0, 0, 0, 90);
      redGradient.addColorStop(0, "#ED4F2C");
      redGradient.addColorStop(1, "rgba(255,0,0,0)");

      const greenGradient = ctx.createLinearGradient(0, 0, 0, 90);
      greenGradient.addColorStop(0, "#13BB56");
      greenGradient.addColorStop(1, "rgba(19, 187, 86, 0)");

      chartReservations.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "My Second Dataset",
              data: [40, 55, 56, 81, 80, 59, 65],
              borderColor: totalReservations < 0 ? "#ED4F2C" : "#13BB56",
              tension: 0.4,
              borderCapStyle: "round",
              pointHoverRadius: 0,
              pointRadius: 0,
              fill: {
                target: "origin",
                above: totalReservations < 0 ? redGradient : greenGradient,
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    const co2Graph = async () => {
      if (!chartRefco2.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getCo2SavingsByPeriod({ period: selectedOption });

        if (res && res.success) {
          const { labels, data } = res.data;

          if (chartco2.current) {
            chartco2.current.destroy();
          }

          const ctx = chartRefco2.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartco2.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "CO2 Poupado (g)",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      co2Graph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const eventGraph = async () => {
      if (!chartRefevents.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getEventsGraph({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartevents.current) {
            chartevents.current.destroy();
          }

          const ctx = chartRefevents.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartevents.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Eventos",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      eventGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const equipmentsGraph = async () => {
      if (!chartRefstructures.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getEquipmentsByPeriod({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartstructures.current) {
            chartstructures.current.destroy();
          }

          const ctx = chartRefstructures.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartstructures.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Equipaments",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      equipmentsGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const partnersGraph = async () => {
      if (!chartRefpartners.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getPartnersByPeriod({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartpartners.current) {
            chartpartners.current.destroy();
          }

          const ctx = chartRefpartners.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartpartners.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Partners",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      partnersGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    getUserGrowth({ period: selectedOption })
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTotalUsers(res.data.difference);
          setPercentageUsers(res.data.percentageChange);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, [selectedOption]);

 

  return (
    <div className="background">
     <HeaderManagerWhite/>
     <NavManager/>
     <div className="centerGraph">
        <div className="rowstats">
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"19.6875rem"}
              height={"11rem"}
              sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
            />
          ) : totalUsers >= 0 ? (
            <div className="greenCard">
              <div className="graphTopRow">
                <a className="whiteTextGraph">{t("totalusers")}</a>
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="selectMonthGrey"
                >
                  <option value="month">{t("month")}</option>
                  <option value="year">{t("year")}</option>
                </select>
              </div>
              <div
                style={{
                  height: "5.225rem",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingInline: 10,
                }}
              >
                <canvas ref={chartRef} />
              </div>
              <div className="graphBottomRow">
                <a className="whiteValue">{totalUsers}</a>
                <div className="percentageGraphWhite">
                  <a className="arrowTextGreen">↑</a>
                  <a className="percentageTextGreen"> {percentageUsers}%</a>
                </div>
              </div>
            </div>
          ) : (
            <div className="redCard">
              <div className="graphTopRow">
                <a className="whiteTextGraph">{t("totalusers")}</a>
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="selectMonthGrey"
                >
                  <option value="month">{t("month")}</option>
                  <option value="year">{t("year")}</option>
                </select>
              </div>
              <div
                style={{
                  height: "5.225rem",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingInline: 10,
                }}
              >
                <canvas ref={chartRef} />
              </div>
              <div className="graphBottomRow">
                <a className="whiteValue">{totalUsers}</a>
                <div className="percentageGraphWhite">
                  <a className="arrowTextRed">↓</a>
                  <a className="percentageTextRed"> {percentageUsers}%</a>
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"19.6875rem"}
              height={"11rem"}
              sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
            />
          ) : totalReservations >= 0 ? (
            <div className="whiteCard">
              <div className="graphTopRow">
                <a className="blackTextGraph">{t("totalreservations")}</a>
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="selectMonthBlack"
                >
                  <option value="month">{t("month")}</option>
                  <option value="year">{t("year")}</option>
                </select>
              </div>
              <div
                style={{
                  height: "5.225rem",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingInline: 10,
                }}
              >
                <canvas ref={chartRefReservations} />
              </div>
              <div className="graphBottomRow">
                <a className="blackValue">{totalReservations}</a>
                <div className="percentageGraphGreen">
                  <a className="arrowTextWhite">↑</a>
                  <a className="percentageTextWhite"> 32%</a>
                </div>
              </div>
            </div>
          ) : (
            <div className="whiteCard">
              <div className="graphTopRow">
                <a className="blackTextGraph">{t("totalreservations")}</a>
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="selectMonthBlack"
                >
                  <option value="month">{t("month")}</option>
                  <option value="year">{t("year")}</option>
                </select>
              </div>
              <div
                style={{
                  height: "5.225rem",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingInline: 10,
                }}
              >
                <canvas ref={chartRefReservations} />
              </div>
              <div className="graphBottomRow">
                <a className="blackValue">{totalReservations}</a>
                <div className="percentageGraphRed">
                  <a className="arrowTextWhite">↓</a>
                  <a className="percentageTextWhite"> 29.5%</a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="rowstats2">
          {loading ? (
            <Skeleton
              variant="rectangular"
              height={"11rem"}
              sx={{
                width: "57%",
                maxWidth: "44%",
                boxSizing: "border-box",
                borderRadius: "0.9375rem",
              }}
            />
          ) : (
            <div className="whiteStatsCard">
              <div className="graphTopRow">
                <a className="blackTextGraph">{t("co2savings")}</a>
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                  className="selectMonthBlack"
                >
                  <option value="month">{t("month")}</option>
                  <option value="year">{t("year")}</option>
                </select>
              </div>
              <div
                style={{
                  height: "8.25rem",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <canvas ref={chartRefco2} />
              </div>
            </div>
          )}
        </div>
        <div className="rowstats2">
        {loading ? (
          <div >
            <Skeleton
              variant="rectangular"
              width= "65.75rem"
              height= "16.5rem" 
              sx={{
                borderRadius: "0.9375rem",
                backgroundColor: "#cfdcd2",
              }}
            />
          </div>
        ) : (
          <div className="whiteHorizontalCard">
            <div style={{ flexDirection: "column", padding: 10 }}>
              <div className="graphRowStats">
                <a className="blackTextGraph">{t("numberevents")}</a>
              </div>
              <div
                style={{
                  height: "180px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                  marginTop: 30,
                 
                }}
              >
                <canvas ref={chartRefevents} />
              </div>
            </div>
            <div style={{ flexDirection: "column", padding: 10 }}>
              <div className="graphRowStats">
                <a className="blackTextGraph">{t("numberstructures")} </a>
              </div>
              <div
                style={{
                  height: "180px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                  marginTop: 30,
               
                }}
              >
                <canvas ref={chartRefstructures} />
              </div>
            </div>
            <div style={{ flexDirection: "column", padding: 10 }}>
              <div className="graphRowStats">
                <a className="blackTextGraph">{t("numberpartners")}</a>
              </div>

              <div
                style={{
                  height: "180px",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 10,
                  marginTop: 30,
                
                }}
              >
                <canvas ref={chartRefpartners} />
              </div>
            </div>
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="selectMonthBlackHorizontal"
            >
              <option value="month">{t("month")}</option>
              <option value="year">{t("year")}</option>
            </select>
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default StatsManager;
