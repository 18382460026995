import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import HeaderClient from "../../components/headerClient";

function OfferPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 segundo para exibir o esqueleto

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="background">
      <NavClient />
      <HeaderClient/>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "4rem",
          marginLeft:"3.45rem"
          
        }}
      >
        OfferPage
      </div>
    </div>
  );
}

export default OfferPage;
