import Cookies from "js-cookie";
import {
  makeRequestDelete,
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); // Check again after 100ms
      }
    };
    checkToken();
  });
}

export async function getUsers() {
  let token = await getToken();
  return makeRequestGet2(`users/`, token);
}

export async function getUser(id) {
  let token = await getToken();
  return makeRequestGet2(`users/${id}`, token);
}

export async function putUser(id, data) {
  let token = await getToken();
  return makeRequestPut2(`users/${id}`, data, token);
}

export async function postUser(data) {
  let token = await getToken();
  return makeRequestPost2(`users/`, data, token);
}

export async function deleteUser(id, data) {
  let token = await getToken();
  return makeRequestDelete2(`users/${id}`, data, token);
}


export async function getUserGrowth(data) {
  let token = await getToken();
  return makeRequestPost2(`users/growth`,data, token);
}

export async function getUserChartData(data) {
  let token = await getToken();
  return makeRequestPost2(`users/growthgraph`,data, token);
}
