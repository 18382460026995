import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import dayjs from "dayjs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/mmachado.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
  isEqual,
  startOfDay,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getEvent, putEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import Avatar from "@mui/material/Avatar";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {getOffer,putOffer } from "../../api/requests/Offers";

function PartnerOfferPageAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const { id } = location.state || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/offerpage");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "clamp(0.875rem, 1.5vw, 1.125rem)",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputRadioStyle = {
    color: "#0F111199",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
 
  
  };
 
  const [name, setName] = useState("");
  const [value, setValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [edicao, setEdicao] = useState(true);
  const [obs, setObs] = useState("");
  const [data, setData] = useState([]);

  const [discount, setDiscount] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [isGroup, setIsGroup] = useState(false);
  const [isFamily, setIsFamily] = useState(false);
  const [isSchools, setIsSchools] = useState(false);
  
  const handleOptionChange = (value) => {

    setIsIndividual(value === "individual");
    setIsGroup(value === "group");
    setIsFamily(value === "family");
    setIsSchools(value === "schools");
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  
    const getOptionStyle = (isSelected) => ({
      ...inputRadioStyle,
      color: isSelected ? "#0F1111" : "#0F111199", 
      fontWeight: isSelected ? 600 : 500, 
    });

    useEffect(() => { 
        getOffer(id)
          .then((res) => {
            if (res.status === 200 && res.success) {
              let data = res.data;
              setData(data);
              setName(data.name);
              setObs(data.obs);
              setIsFamily(data.family);
              setIsGroup(data.group);
              setIsSchools(data.schools);
              setIsIndividual(data.individual);
              setDiscount(data.discount);
            } else {
              toast.error(res.error);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
  
            toast.error("500");
          });
      
    }, []);
  
    function originData() {
      setData(data);
      setName(data.name);
      setObs(data.obs);
      setIsFamily(data.family);
      setIsGroup(data.group);
      setIsSchools(data.schools);
      setIsIndividual(data.individual);
      setDiscount(data.discount);
    }

    function saveData() {
      if (!name) {
        toast.error(t("nameEqValidation"));
        return;
      }
      if (!obs) {
        toast.error(t("obsEqValidation"));
        return;
      }
      if (!discount) {
        toast.error(t("discountValidation"));
        return;
      }

      if (!isIndividual && !isFamily && !isGroup && !isSchools) {
        toast.error(t("typeValidation"));
        return;
      }
     
  
      putOffer(id, {
        name: name,
        obs: obs,
        discount: discount,
        individual: isIndividual,
        group: isGroup,
        schools:isSchools,
        family: isFamily,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    }
  return (
    <div className="background">
      <Header />
      <NavAdmin />
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("partnerOffer")}</a>

        <div className="whiteBigInfoCard">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              height: "85%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("discountpercent")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      value={discount}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("obs")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setObs(e.target.value);
                      }}
                      value={obs}
                    />
                  )}
                </div>
                <FormControl component="fieldset" sx={{ marginTop: "30px" }}>
                  <FormLabel style={{ ...inputLabelPropsStyle, color: "#044535", textAlign:"left" }}>{t("selectOption")}:</FormLabel>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={150} 
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <RadioGroup
                      aria-label="selector"
                      name="selector"
                      
                      value={
                        isIndividual
                          ? "individual"
                          : isGroup
                          ? "group"
                          : isFamily
                          ? "family"
                          : isSchools
                          ? "schools"
                          : ""
                      }
                      onChange={(e) => handleOptionChange(e.target.value)}
                    >
                      <FormControlLabel
                      disabled={edicao}
                        value="individual"
                        control={<Radio />}
                        label={t("individual")}
                        sx={getOptionStyle(isIndividual)}
                       
                      />
                      <FormControlLabel
                       disabled={edicao}
                        value="group"
                        control={<Radio />}
                        label={t("group")}
                        sx={getOptionStyle(isGroup)}
                      />
                      <FormControlLabel
                       disabled={edicao}
                        value="family"
                        control={<Radio />}
                        label={t("family")}
                        sx={getOptionStyle(isFamily)}
                      />
                      <FormControlLabel
                       disabled={edicao}
                        value="schools"
                        control={<Radio />}
                        label={t("school")}
                        sx={getOptionStyle(isSchools)}
                      />
                    </RadioGroup>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                if (edicao) {
                  originData();
                  setEdicao(!edicao);
                } else {
                  saveData();
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button
                onClick={() => {
                  originData();
                  setEdicao(!edicao);
                }}
                className="clientInfoCancelButton"
              >
                {" "}
                {t("cancel")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerOfferPageAdmin;
