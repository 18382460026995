import { makeRequestGet, makeRequestPost } from "../api";

export function sendPasswordResetEmail(dados) {
  return makeRequestPost("emails/send-password-reset-email", dados);
}

export function resetNewPassword(dados) {
  return makeRequestPost("emails/reset-password", dados);
}

export function registerEmail(dados) {
  return makeRequestPost("emails/send-welcome-email", dados);
}

export function confirmEmail(dados) {
  return makeRequestPost("emails/users/confirm-email", dados);
}
