import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const Search1 = ({ onSearch }) => {

  const { t, i18n } = useTranslation();

  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleSearchButtonClick = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };
  return (
    <div style={{ textAlign: "start",flexDirection:"row", display:"flex"}}>
<span 
    className="searchButton" 
    onClick={handleSearchButtonClick}  
    style={{
      marginLeft:"0.625rem",
      zIndex:2
    }}
  >
  </span>
    <TextField
      variant="outlined"
      label={t("search")}
      placeholder={t("search1")}
      className="search"
      type={"text"}
      id="search"
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
          
          
        },
        "& .MuiInputLabel-root": {
          content: '""',
          display: "inline-block",
          marginLeft: "0.313rem", 
          marginTop: "-0.75rem",
          opacity: isFocused ? 0 : 1,
          color:"#044535"
         
             },
        "& .MuiInputLabel-root::before": {
          content: '""',
        
          display: "inline-block",
          marginLeft: "20px",
          
          color:"#044535"
                   },
       
        "& .MuiInputBase-root": {
          marginLeft: "1.25rem", 
          height:"1.813rem",
        
          border:isSearchExpanded? 1:0,
          borderColor:"#044535"
  
       
        },
        width:isSearchExpanded? "18.75rem" : "0px",
        backgroundColor:"transparent !important" ,
        zIndex:1,
      
      }}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      value={search}
      onChange={(e) => onInputChange(e.target.value)}
    />
    </div>
  );
};

export default Search1;
