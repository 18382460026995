import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/feira.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import { postEvent } from "../../api/requests/Event.js";

function EventCreateAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = ["Teatro", "Cinema", "Futebol"];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const DateCell = ({ value }) => {
    const today = new Date();
    const eventDates = value.split(" ");
    const startDate = parse(eventDates[0], "dd/MM/yyyy", new Date());
    const endDate =
      eventDates.length > 1
        ? parse(eventDates[1], "dd/MM/yyyy", new Date())
        : startDate;

    const isPast = isAfter(today, endDate);
    const isFuture = isBefore(today, startDate);
    const isOngoing = !isPast && !isFuture;

    let statusText = "";
    let statusColor = "";

    if (isPast) {
      statusText = t("finished");
      statusColor = "#ED4F2C";
    } else if (isOngoing) {
      statusText = t("live");
      statusColor = "#13BB56";
    } else if (isFuture) {
      const daysToStart = differenceInDays(startDate, today);
      statusText = `${t("in")} ${daysToStart} ${t("days")}`;
      statusColor = "#B0B0B0";
    }

    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <div
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: statusColor,
            borderRadius: "3px",
          }}
        ></div>
        <a className="dateEventText">{statusText}</a>
      </div>
    );
  };

  const handleAllowRegisterChange = (event) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (event) => {
    setAudience(!audience);
  };

  const handleMinimumAgeChange = (event) => {
    setMinAge(event.target.value);
  };

  const handlePromoterChange = (event) => {
    setPromoterId(event.target.value);
  };

  const handleCouncilChange = (event) => {
    setCouncilId(event.target.value);
  };

  const handleEquipmentChange = (event) => {
    setIdEquipment(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };

  const handleSubModalityChange = (event, newValue) => {
    setSubmodality(newValue);
  };

  const today = dayjs();

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startDateregister, setStartDateRegister] = useState(today);
  const [endDateregister, setEndDateRegister] = useState(today);

  const [value, setValue] = React.useState("+351999999999");

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState(null);
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState("");
  const [discount, setDiscount] = useState(0);
  const [endRegDate, setEndRegDate] = useState(today);
  const [eventUrl, setEventUrl] = useState("");
  const [free, setFree] = useState("");
  const [gallery, setGallery] = useState([]);
  const [idEquipment, setIdEquipment] = useState("");
  const [minAge, setMinAge] = useState(0);
  const [modality, setModality] = useState(null);
  const [name, setName] = useState("");
  const [obs, setObs] = useState("");
  const [poster, setPoster] = useState([]);
  const [promoterId, setPromoterId] = useState("");
  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(today);
  const [submodality, setSubmodality] = useState(null);
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [video, setVideo] = useState("");
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [subactivities, setSubActivities] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [status, setStatus] = useState(0);
  const [filteredSubactivities, setFilteredSubactivities] =
    useState(subactivities);

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const [preview, setPreview] = useState(null);
  const [preview1, setPreview1] = useState(null);
  const [preview2, setPreview2] = useState(null);
  const [preview3, setPreview3] = useState(null);
  const [preview4, setPreview4] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const options = [
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
    { label: t("phone"), value: "phone" },
    { label: t("email"), value: "email" },
    { label: t("company"), value: "company" },
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const checkedIcon = <CheckBoxIcon fontSize="medium" />;

  const [selectedOptions, setSelectedOptions] = useState([
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
  ]);

  const handleChangeOption = (event, value) => {
    setSelectedOptions(value);
  };
  function goBack() {
    navigate("/eventsadmin");
  }
  const [trainstations, setTrainstations] = useState([]);

  useEffect(() => {
    if (modality != null) {
      setFilteredSubactivities(
        subactivities.filter(
          (activity) => activity.id_modality.toString() === modality.id
        )
      );
    }
  }, [modality, subactivities]);

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);
  const [search, setSearch] = useState("");
  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStations, setSelectedStations] = useState([]);
  const handleStationChange = (event, value) => {
    setSelectedStation(value);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getEquipments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setEquipments(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getSubActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setSubActivities(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  function create() {
    if (!preview) {
      toast.error(t("posterValidation"));
      return;
    }
    if (!startDate) {
      toast.error(t("startDateValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (!name) {
      toast.error(t("nameEventValidation"));
      return;
    }
    if (!free != "") {
      toast.error(t("freeValidation"));
      return;
    }
    if (!district) {
      toast.error(t("districtValidation"));
      return;
    }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }
    if (!typeId) {
      toast.error(t("typeIdValidation"));
      return;
    }
    if (!modality) {
      toast.error(t("modalityValidation"));
      return;
    }
    if (!submodality) {
      toast.error(t("submodalityValidation"));
      return;
    }

    postEvent({
      name: name,
      poster: poster,
      start_date: startDate,
      end_date: endDate,
      start_reg_date: startRegDate,
      end_reg_date: endRegDate,
      free: free,
      booking: booking,
      coordinates: coordinates,
      gallery: [preview1, preview2, preview3, preview4],
      video: video,
      event_url: eventUrl,
      promoter_id: promoterId,
      city_council_id: councilId,
      min_age: minAge,
      booking_limit: bookingLimit,
      audience: audience,
      audience_capacity: audienceCapacity,
      audience_url: audienceUrl,
      visible: visible,
      discount: discount,
      discount_code: discountCode,
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_youtube: urlYoutube,
      url_linkedin: urlLinkedin,
      url_twitter: urlTwitter,
      obs: obs,
      type: typeId,
      modality: modality,
      submodality: submodality,
      district_id: district.id,
      station: selectedStation.id,
      discount_code: discountCode,
      status: 1,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          navigate("/eventsadmin");
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  return (
    <div className="background">
      <Header />
      <NavAdmin />
      <div className="centerpage">
        <div className="topLeftBack">
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              size={"small"}
              style={{ fill: "#044535", zIndex: 99 }}
            />
          </IconButton>
        </div>
        <a className="greenTitle">{t("createEvent")}</a>
        <div className="whiteBoard">
          <Dialog
            open={openDialogRegister}
            onClose={handleDialogRegisterClose}
            PaperProps={{
              style: { borderRadius: 15, width: "100%", maxWidth: "800px" },
            }}
          >
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
              }}
            >
              <DialogContentText
                style={{
                  background: "#FFF",
                  marginTop: "20px",
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a className="textCancel">{t("subscriptions")}</a>
              </DialogContentText>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={6}>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("allowRegister")}</a>
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={booking}
                      onChange={handleAllowRegisterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("startdateregister")}</a>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DatePicker
                        variant="standard"
                        value={startRegDate}
                        onChange={(e) => {
                          setStartRegDate(e);
                        }}
                        disabled={booking === false}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },
                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width: "60%",
                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "18px !important",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            // background: true ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("enddateregister")}</a>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DatePicker
                        variant="standard"
                        value={endRegDate}
                        onChange={(e) => {
                          setEndRegDate(e);
                        }}
                        disabled={booking === false}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },
                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },
                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width: "60%",
                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },
                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            // background: true ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("numberParticipants")}</a>
                    <TextField
                      fullWidth
                      disabled={booking === false}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={bookingLimit}
                      onChange={(e) => {
                        setBookingLimit(e.target.value);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  {/* <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("registerData")}</a>
                    <Autocomplete
                      multiple
                      options={options}
                      disabled={editSubs || booking === false}
                      variant="standard"
                      sx={{
                        fontFamily: "Montserrat !important",
                        border: "0px",
                        borderBottom: "1px transparent !important",
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                        "& .MuiInputBase-root-MuiInput-root": {
                          fontFamily: "Montserrat !important",
                          border: "0px",
                        },
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{
                              fontFamily: "Montserrat !important",
                            }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            fontFamily: "Montserrat !important",
                            borderBottom: "1px transparent !important",
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                            ".MuiInputBase-root-MuiInput-root": {
                              fontFamily: "Montserrat !important",
                              borderBottom: "0px solid !important",
                            },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::after":
                              {
                                borderBottom: "0px solid !important",
                              },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::before":
                              {
                                borderBottom: "0px solid !important",
                              },
                          }}
                          variant="standard"
                        />
                      )}
                      value={selectedOptions}
                      onChange={handleChangeOption}
                    />
                  </div> */}
                  {/* <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("price")}</a>
                    <TextField
                      fullWidth
                      disabled={editSubs || booking === false}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={50}
                    />
                  </div> */}
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("discount")}</a>
                    <TextField
                      type="number"
                      fullWidth
                      disabled={booking === false}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                      value={discount}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("discountCode")}</a>
                    <TextField
                      fullWidth
                      disabled={booking === false}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      onChange={(e) => {
                        setDiscountCode(e.target.value);
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={discountCode}
                    />
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
          <div className="scrollableContentEvent">
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photosEvent">
                  <div className="photoColumn">
                    <a className="photoLabel">{t("poster")}*</a>
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addPoster" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!preview ? (
                              <div className="addPhoto">
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "rgba(15, 17, 17, 0.60)",
                                  }}
                                  icon={faPlus}
                                />
                              </div>
                            ) : (
                              <div className="addPosterEdit">
                                <img src={preview} className="addPoster" />
                                <div className="overlay">
                                  <FontAwesomeIcon
                                    className="hoverIcon"
                                    icon={faPen}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>

                  <div className="morePhotos">
                    <a className="photoLabel">{t("photosvideos")}</a>
                    <div className="photoRow">
                      <>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview1(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop1}
                            disabled={preview1 ? true : false}
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview1 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview1}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview2(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop2}
                            disabled={preview2 ? true : false}
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview2 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview2}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview3(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop3}
                            accept="image/*"
                            disabled={preview3 ? true : false}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview3 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview3}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview4(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop4}
                            accept="image/*"
                            multiple={false}
                            disabled={preview4 ? true : false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview4 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview4}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("startdate")}*</a>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18next?.language}
                  >
                    <DateTimePicker
                      variant="standard"
                      showTimeSelect
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                      }}
                      slotProps={{
                        tabs: {
                          hidden: false,
                        },

                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },

                        desktopPaper: {
                          sx: {
                            borderRadius: "8px",
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            ".MuiPickersMonth-monthButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                              {
                                backgroundColor: "#13BB56 !important",
                              },
                            "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                              {
                                color: "#13BB56 !important",
                              },
                            "& .css-1aquho2-MuiTabs-indicator": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                              {
                                color: "#13BB56 !important",
                              },
                          },
                        },
                      }}
                      sx={{
                        width: "60%",

                        "& .MuiInputBase-input.Mui-disabled": {
                          backgroundColor: "white",
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#44b887 !important",
                          color: "white",
                        },
                        "& .MuiPickersCalendar-root": {
                          borderRadius: "8px !important",
                        },

                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",

                          fontFamily: "Montserrat",
                          fontSize: "18px !important",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                          position: "absolute",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#0F111199 !important",
                          textAlign: "right",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "15px",
                          borderRadius: "8px",
                          background: "#0f11110d",
                          borderColor: "transparent !important",
                          width: "100%",
                          height: "34.88px",
                          padding: "16.5px 10px",
                        },
                        "& .MuiInputLabel-root.Mui-disabled ": {
                          color: "#044535 !important",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline ": {
                          border: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "16.5px 0px",
                          width: "100%",
                        },
                      }}
                    ></DateTimePicker>
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("enddate")}*</a>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={i18next?.language}
                  >
                    <DateTimePicker
                      variant="standard"
                      showTimeSelect
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e);
                      }}
                      slotProps={{
                        tabs: {
                          hidden: false,
                        },

                        day: {
                          sx: {
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                          },
                        },

                        desktopPaper: {
                          sx: {
                            borderRadius: "8px",
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            ".MuiPickersMonth-monthButton.Mui-selected": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                              {
                                backgroundColor: "#13BB56 !important",
                              },
                            "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                              {
                                color: "#13BB56 !important",
                              },
                            "& .css-1aquho2-MuiTabs-indicator": {
                              backgroundColor: "#13BB56 !important",
                            },
                            "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                              {
                                color: "#13BB56 !important",
                              },
                          },
                        },
                      }}
                      sx={{
                        width: "60%",

                        "& .MuiInputBase-input.Mui-disabled": {
                          backgroundColor: "white",
                        },
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor: "#44b887 !important",
                          color: "white",
                        },
                        "& .MuiPickersCalendar-root": {
                          borderRadius: "8px !important",
                        },

                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",

                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                          position: "absolute",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#0F111199 !important",
                          textAlign: "right",
                          fontFamily: "Montserrat",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "15px",
                          borderRadius: "8px",
                          background: "#0f11110d",
                          borderColor: "transparent !important",
                          width: "100%",
                          height: "34.88px",
                          padding: "16.5px 10px",
                        },
                        "& .MuiInputLabel-root.Mui-disabled ": {
                          color: "#044535 !important",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "15px",
                          borderColor: "transparent !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline ": {
                          border: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "16.5px 0px",
                          width: "100%",
                        },
                      }}
                    ></DateTimePicker>
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("event")}*</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    multiline={true}
                    rows={2}
                    variant="standard"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowRegister")}</a>
                  <TextField
                    fullWidth
                    disabled={true}
                    onClick={() => {
                      setOpenRegisterDialog(true);
                    }}
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    // margin="normal"
                    value={booking}
                    onChange={handleAllowRegisterChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={true}>{t("yes")}</MenuItem>
                    <MenuItem value={false}>{t("no")}</MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowPublic")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={audience}
                    onChange={handleAllowPublicChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={true}>{t("yes")}</MenuItem>
                    <MenuItem value={false}>{t("no")}</MenuItem>
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("free")}?*</a>

                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    // margin="normal"
                    value={free}
                    onChange={(e) => {
                      setFree(e.target.value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value={true}>{t("yes")}</MenuItem>
                    <MenuItem value={false}>{t("no")}</MenuItem>
                  </TextField>
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("audienceCapacity")}</a>

                  <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    // margin="normal"
                    value={audienceCapacity}
                    onChange={(e) => {
                      setAudienceCapacity(e.target.value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("audienceURL")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    // margin="normal"
                    value={audienceUrl}
                    onChange={(e) => {
                      setAudienceUrl(e.target.value);
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("minimumAge")}</a>
                  <TextField
                    fullWidth
                    type="number"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={minAge}
                    onChange={handleMinimumAgeChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("observations")}</a>
                  <TextField
                    fullWidth
                    multiline={true}
                    rows={6}
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    onChange={(e) => {
                      setObs(e.target.value);
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={obs}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={visible}
                        onChange={handleSwitchChange}
                        color="primary"
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "#FFFFFF !important",
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "#13BB56 !important",
                              opacity: "1 !important",
                            },
                        }}
                      />
                    }
                    label={t("visible")}
                    style={{ marginTop: "20px" }}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        flexGrow: 1,
                        textAlign: "left",
                        marginLeft: "10px",
                        color: "#0F1111",
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("promoter")}</a>
                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={promoterId}
                    onChange={handlePromoterChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {promoters.map((promoter) => (
                      <MenuItem key={promoter._id} value={promoter._id}>
                        {promoter.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("cityCouncil")}</a>

                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={councilId}
                    onChange={handleCouncilChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {cityCouncils.map((council) => (
                      <MenuItem key={council._id} value={council._id}>
                        {council.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipment")}</a>

                  <TextField
                    fullWidth
                    select
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...inputPropsStyle,
                        textAlign: "left",
                        borderRadius: "8px",
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={idEquipment}
                    onChange={handleEquipmentChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {equipments.map((equipment) => (
                      <MenuItem key={equipment._id} value={equipment._id}>
                        {equipment.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("district")}*</a>

                  <Autocomplete
                    options={districts}
                    getOptionLabel={(option) =>
                      option[i18next.language] || option.en
                    }
                    value={district}
                    onChange={handleDistrictChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}*</a>

                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    onChange={(e) => {
                      setCoordinates(e.target.value);
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    value={coordinates}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("nearStation")}</a>
                  <Autocomplete
                    options={filteredStations}
                    getOptionLabel={(option) => option.designation}
                    value={selectedStation}
                    onChange={handleStationChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventLink")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setEventUrl(e.target.value);
                    }}
                    value={eventUrl}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventVideo")}</a>

                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setVideo(e.target.value);
                    }}
                    value={video}
                  />
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventFacebook")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setUrlFacebook(e.target.value);
                    }}
                    value={urlFacebook}
                  />
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventInstagram")}</a>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setUrlInstagram(e.target.value);
                    }}
                    value={urlInstagram}
                  />
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("eventYoutube")}</a>

                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("eventLinkedin")}</a>

                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("eventTwitter")}</a>

                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("type")}*</a>

                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={typeId}
                      onChange={handleChangeType}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type.id}>
                          {type[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("modality")}*</a>

                    <Autocomplete
                      options={
                        typeId === "1" ? sportsActivities : culturalActivities
                      }
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={modality}
                      onChange={handleModalityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("submodality")}*</a>

                    <Autocomplete
                      options={filteredSubactivities}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={submodality}
                      onChange={handleSubModalityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                create();
              }}
            >
              {t("create")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCreateAdmin;
