import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import { verifyEmail } from "../../api/requests/Auth";

function VerifyEmailPromoter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      verifyEmail({ token: token })
        .then((res) => {
          if (res.success && res.status == 200) {
            toast.success(res.data.message);
            navigate("/loginpromoter");
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    }
  }, []);

  return (
    <div className="background">
      <div className="backgroundImage">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="title">{t("verifyEmail")}</p>
        </div>
      </div>
      <div className="bottomcenter"></div>
    </div>
  );
}

export default VerifyEmailPromoter;
