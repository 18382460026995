import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getPartners } from "../../api/requests/Partner";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";

function PartnersAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardadmin");
  }
  const [partners, setPartners] = useState([]);
  const [partnersCopy, setPartnersCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginadmin");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getPartners()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data);
          setPartnersCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPartners = partnersCopy;
      arrayPartners = arrayPartners.filter(
        (element) =>
          element.name.toLowerCase().includes(search.toLowerCase()) ||
          element.address.toLowerCase().includes(search.toLowerCase()) ||
          element.district_name.toLowerCase().includes(search.toLowerCase()) ||
          element.principal_service_type
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setPartners(arrayPartners);
    } else {
      setPartners(partnersCopy);
    }
  }, [search]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const EventCell = ({ row }) => {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const maxLength = Math.floor(containerWidth / 3.5);

    return (
      <div
        ref={containerRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        <img src={row.photo} alt={row.name} className="imageGrid" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            gap: "5px",
            width: "100%",
          }}
        >
          <span className="eventGridText">
            {truncateText(row.name, maxLength)}
          </span>
        </div>
      </div>
    );
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    principal_service_type: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      principal_service_type: !isSmallScreen,
    });
  }, [isSmallScreen]);

  return (
    <div className="background">
      <Header />
      <NavAdmin />
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("partners")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
            <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {
                navigate("/offerpage");
              }}
            >
              {t("partneroffers")}
            </span>
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                rowHeight={80}
                autoPageSize={true}
                columnVisibilityModel={columnVisibilityModel}
                loading={loading}
                columns={[
                  {
                    field: "partner",
                    headerName: t("partner"),
                    type: "string",
                    flex: 2.5,
                    renderCell: (params) => <EventCell row={params.row} />,
                  },

                  {
                    field: "address",
                    headerName: t("address"),
                    flex: 1,
                    type: "string",
                    renderCell: (params) => (
                      <div
                        style={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          lineHeight: "1.2",
                          fontSize: "clamp(10px, 1.5vw, 14px)",
                        }}
                      >
                        {params.value}
                      </div>
                    ),
                  },
                  {
                    field: "principal_service_type",
                    headerName: t("activity"),
                    flex: 1,
                    type: "string",
                  },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 0.5,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{
                                fontSize: "clamp(14px, 1.5vw, 18px)",
                                color: "#0F1111",
                              }}
                              icon={faPen}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/partnerpageadmin", {
                              state: { partnerId: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell": {
                    border: "none",
                    padding: "0 !important",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "clamp(10px, 1.5vw, 14px)",
                  },
                  ".MuiDataGrid-filler": {
                    display: "none",
                  },

                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },

                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  '& .MuiDataGrid-cell[data-field="local"]': {
                    fontSize: "20px",
                  },
                  '& .MuiDataGrid-cell[data-field="registered"]': {
                    fontSize: "18px",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: "clamp(14px, 2vw, 18px)",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontSize: "clamp(10px, 1.5vw, 16px)",
                  },
                }}
                rows={partners}
                checkboxSelection={false}
                getRowClassName={() => "custom-row"}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/partnercreateadmin");
              }}
            >
              {t("addPartner")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersAdmin;
