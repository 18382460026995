import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import { postUser } from "../../api/requests/User.js";
import toast from "react-hot-toast";
import {
  getActivities,
  getCountries,
  getDistricts,
  getGenders,
  getMunicipalities,
  getTrainStations,
} from "../../api/requests/Data.js";
import { checkRegistration } from "../../api/requests/Auth.js";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Colors } from "../../values/Colors.js";

function ClientAddPageAdmin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  function goBack() {
    navigate("/clientsadmin");
  }

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const [age, setAge] = useState(0);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = React.useState(null);
  const [gender, setGender] = useState("");
  const [district, setDistrict] = useState(null);
  const [municipality, setMunicipality] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [municipalitiesCopy, setMunicipalitiesCopy] = useState("");
  const [genders, setGenders] = useState([]);
  const [trainstations, setTrainstations] = useState([]);
  const [trainstationsUser, setTrainstationsUser] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [occupation, setOccupation] = useState("");
  const [status, setStatus] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const SkeletonTextField = () => (
    <Skeleton
      variant="text"
      width="50%"
      height={50}
      style={{ marginTop: "0px", marginLeft: "10px" }}
    />
  );

  const handleCountryChange = (event, newValue) => {
    setCountry(newValue);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  const handleMunChange = (event, newValue) => {
    setMunicipality(newValue);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setPreview(base64String);
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (birthDate != null) {
      const calculateAge = (birthDate) => {
        const birthDay = dayjs.isDayjs(birthDate)
          ? birthDate
          : dayjs(birthDate);
        const today = dayjs();
        let age = today.year() - birthDay.year();
        if (
          today.month() < birthDay.month() ||
          (today.month() === birthDay.month() && today.date() < birthDay.date())
        ) {
          age--;
        }
        return age;
      };

      setAge(calculateAge(birthDate));
    }
  }, [birthDate]);

  useEffect(() => {
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getMunicipalities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setMunicipalities(res.data);
          setMunicipalitiesCopy(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getCountries()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCountries(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id === 1) {
              sports.push(element);
            } else if (element.type_id === 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getGenders()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setGenders(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  const [editStationsDialogOpen, setEditStationsDialogOpen] = useState(false);
  const [editPreferencesDialogOpen, setEditPreferencesDialogOpen] =
    useState(false);

  const handleEditStationsClick = () => {
    setSelectedStations(trainstationsUser);
    setEditStationsDialogOpen(true);
  };

  const handleEditPreferencesClick = () => {
    setEditPreferencesDialogOpen(true);
  };

  const handleCloseStationsDialog = () => {
    setEditStationsDialogOpen(false);
  };

  const handleClosePreferencesDialog = () => {
    setEditPreferencesDialogOpen(false);
  };
  const [preview, setPreview] = useState("");
  const [selectedStations, setSelectedStations] = useState([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleSearch2 = (event) => {
    setSearch2(event.target.value);
  };

  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );
  const filteredCultural = culturalActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );

  const filteredSports = sportsActivities.filter(
    (activity) =>
      activity.pt.toLowerCase().includes(search2.toLowerCase()) ||
      activity.es.toLowerCase().includes(search2.toLowerCase()) ||
      activity.en.toLowerCase().includes(search2.toLowerCase()) ||
      activity.fr.toLowerCase().includes(search2.toLowerCase()) ||
      activity.de.toLowerCase().includes(search2.toLowerCase())
  );
  const [selectedOption, setSelectedOption] = useState("Cultural");
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const [selectedCulturalActivities, setSelectedCulturalActivities] = useState(
    []
  );

  const handleCulturalClick = (activity) => {
    if (selectedCulturalActivities.includes(activity)) {
      setSelectedCulturalActivities(
        selectedCulturalActivities.filter((item) => item !== activity)
      );
    } else {
      setSelectedCulturalActivities([...selectedCulturalActivities, activity]);
    }
  };

  const [selectedSportsActivities, setSelectedSportsActivities] = useState([]);

  const handleSportsClick = (activity) => {
    if (selectedSportsActivities.includes(activity)) {
      setSelectedSportsActivities(
        selectedSportsActivities.filter((item) => item !== activity)
      );
    } else {
      setSelectedSportsActivities([...selectedSportsActivities, activity]);
    }
  };

  const handleStationClick = (station) => {
    if (selectedStations.includes(station)) {
      setSelectedStations(selectedStations.filter((item) => item !== station));
    } else {
      if (selectedStations.length < 3) {
        setSelectedStations([...selectedStations, station]);
      } else {
        toast.error(t("stationError"));
      }
    }
  };

  async function saveData() {
    if (!name) {
      toast.error(t("nameValidation"));
      return;
    }
    if (!lastname) {
      toast.error(t("lastNameValidation"));
      return;
    }
    if (!birthDate) {
      toast.error(t("birthDateValidation"));
      return;
    } else {
      const birthDay = dayjs(birthDate);
      if (!birthDay.isValid()) {
        toast.error(t("invalidBirthDate"));
        return;
      }
    }
    if (!gender) {
      toast.error(t("genderValidation"));
      return;
    }
    if (!postalCode) {
      toast.error(t("postalCodeValidation"));
      return;
    }
    if (!country) {
      toast.error(t("countryValidation"));
      return;
    }
    if (!district) {
      toast.error(t("districtValidation"));
      return;
    }
    if (!municipality) {
      toast.error(t("municipalityValidation"));
      return;
    }
    if (!email) {
      toast.error(t("emailValidation"));
      return;
    } else {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(email)) {
        toast.error(t("invalidEmail"));
        return;
      }
    }

    let errorOccurred = false;

    const checkEmailRegistration = async () => {
      try {
        const res = await checkRegistration({ email: email });
        if (res.status === 200 && res.success) {
          errorOccurred = true;
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error(error);
      }
    };
    const validatePostalCode = () => {
      const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;
      if (!postalCodeRegex.test(postalCode)) {
        errorOccurred = true;
        toast.error(t("invalidPostalCode"));
      }
    };

    checkEmailRegistration().then(() => {
      if (!errorOccurred) {
        validatePostalCode();

        if (!errorOccurred) {
          let arrayTrainStations = [];
          selectedStations.forEach((element) => {
            arrayTrainStations.push(element.id);
          });

          let cultural = [];
          selectedCulturalActivities.forEach((element) => {
            cultural.push(element.id);
          });

          let sports = [];
          selectedSportsActivities.forEach((element) => {
            sports.push(element.id);
          });

          postUser({
            first_name: name,
            last_name: lastname,
            birth_date: birthDate,
            gender: gender,
            postal_code: postalCode,
            country: country.id.toString(),
            district: district.id.toString(),
            municipality: municipality.id.toString(),
            country_code: country.country_code,
            zip_code: postalCode,
            occupation: occupation,
            email: email,
            photo: preview,
            status: status,
            train_station: arrayTrainStations,
            culture_preference: cultural,
            sports_preference: sports,
          })
            .then((res) => {
              if (res.success && res.status === 200) {
                toast.success(t("successMessage"));
                navigate("/clientsadmin");
              } else {
                toast.error(res.error);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        }
      }
    });
  }

  return (
    <div className="background">
      <Header />
      <NavAdmin />
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitleClientData">{t("clientCreate")}</a>

        <div className="whiteBoard">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photosEvent">
                  <div className="photoColumn">
                    <a className="photoLabel">{t("mainPhoto")}</a>
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addPoster" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!preview ? (
                              <div className="addPhoto">
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "rgba(15, 17, 17, 0.60)",
                                  }}
                                  icon={faPlus}
                                />
                              </div>
                            ) : (
                              <div className="addPosterEdit">
                                <img src={preview} className="addPoster" />
                                <div className="overlay">
                                  <FontAwesomeIcon
                                    className="hoverIcon"
                                    icon={faPen}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {isLoading ? (
                  <>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("firstname")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel"> {t("lastname")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("birthdate")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel"> {t("gender")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel"> {t("postalcode")}</a>
                      <SkeletonTextField />
                    </FormControl>
                  </>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <a className="photoLabel">{t("firstname") + "*"}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("firstname") + "*"}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("firstname")}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                    <a className="photoLabel">{t("lastname") + "*"}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("lastname") + "*"}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("lastname")}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      value={lastname}
                    />
                    <a className="photoLabel">{t("birthdate") + "*"}</a>
                    <div style={{ marginLeft: "0px" }}>
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18next?.language}
                      >
                        <DatePicker
                          openTo="day"
                          variant="standard"
                          // label={t("birthdate") + "*"}
                          views={["day", "month", "year"]}
                          disableFuture
                          value={birthDate}
                          format="DD/MM/YYYY"
                          onChange={(e) => {
                            setBirthDate(e);
                          }}
                          slotProps={{
                            tabs: {
                              hidden: false,
                            },

                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#13BB56 !important",
                                },
                              },
                            },

                            desktopPaper: {
                              sx: {
                                borderRadius: "8px",
                                ".MuiPickersYear-yearButton.Mui-selected": {
                                  backgroundColor: "#13BB56 !important",
                                },
                                ".MuiPickersMonth-monthButton.Mui-selected": {
                                  backgroundColor: "#13BB56 !important",
                                },
                              },
                            },
                          }}
                          sx={{
                            width: "60%",

                            "& .MuiInputBase-input.Mui-disabled": {
                              backgroundColor: "white",
                            },
                            "& .MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#44b887 !important",
                              color: "white",
                            },
                            "& .MuiPickersCalendar-root": {
                              borderRadius: "8px !important",
                            },

                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",

                              fontFamily: "Montserrat",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "15px",
                              borderColor: "transparent !important",
                              position: "absolute",
                              // marginTop: "-5px",
                              // marginLeft: "-10px",
                            },
                            "& .MuiOutlinedInput-root": {
                              color: "#0F111199 !important",
                              textAlign: "right",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "5px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              borderColor: "transparent !important",
                              width: "100%",
                              height: "34.88px",
                              padding: "16.5px 10px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            },
                            "& .MuiInputLabel-root.Mui-disabled ": {
                              color: "#044535 !important",
                              fontFamily: "Montserrat",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "15px",
                              borderColor: "transparent !important",
                            },
                            "& .MuiOutlinedInput-notchedOutline ": {
                              border: "0px",
                            },
                            "& .MuiOutlinedInput-input": {
                              padding: "16.5px 0px",
                              width: "100%",
                            },
                          }}
                        ></DatePicker>
                      </LocalizationProvider>
                    </div>
                    <a className="photoLabel">{t("gender") + "*"}</a>
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("gender") + "*"}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("gender")}
                      value={gender}
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {genders.map((genderOption) => (
                        <MenuItem key={genderOption.id} value={genderOption.id}>
                          {t(genderOption[i18next.language])}
                        </MenuItem>
                      ))}
                    </TextField>
                    <a className="photoLabel">{t("postalcode") + "*"}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("postalcode") + "*"}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("postalcode")}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                      }}
                      value={postalCode}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={6}>
                {isLoading ? (
                  <>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("country")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("district")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("municipality")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("occupation")}</a>
                      <SkeletonTextField />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <a className="photoLabel">{t("email")}</a>
                      <SkeletonTextField />
                    </FormControl>
                  </>
                ) : (
                  <>
                    <a className="photoLabel">{t("country") + "*"}</a>
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={country}
                      onChange={handleCountryChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          // label={t("country") + "*"}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("country")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                    <a className="photoLabel">{t("district") + "*"}</a>
                    <Autocomplete
                      options={districts}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={district}
                      onChange={handleDistrictChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          // label={t("district") + "*"}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("district")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                    <a className="photoLabel">{t("municipality") + "*"}</a>
                    <Autocomplete
                      options={municipalities}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={municipality}
                      onChange={handleMunChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          // label={t("municipality") + "*"}
                          variant="standard"
                          margin="normal"
                          // placeholder={t("municipality")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                    <a className="photoLabel">{t("occupation")}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("occupation")}
                      variant="standard"
                      margin="normal"
                      // placeholder={t("occupation")}
                      onChange={(e) => {
                        setOccupation(e.target.value);
                      }}
                      value={occupation}
                    />
                    {/* <MuiTelInput
                  disabled={edicao}
                  fullWidth
                  defaultCountry="PT"
                  onChange={handleChange}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  label={t("phone")}
                  value={value}
                  margin="normal"
                /> */}
                    <a className="photoLabel">{t("email") + "*"}</a>
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      // label={t("email") + "*"}
                      variant="standard"
                      margin="normal"
                      placeholder="example@email.com"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </>
                )}

                <div className="mainInfoClient">
                  <a className="photoLabel">{t("stations")}</a>
                  {/* <a className="infoClientTitleCard2">{t("stations")}</a> */}
                  <div className="row" style={{ marginTop: 5 }}>
                    {selectedStations.slice(0, 3).map((station) => (
                      <div className="infoClientGreenCard" key={station.id}>
                        <a className="infoClientGreenCardText">
                          {station.designation}
                        </a>
                      </div>
                    ))}
                    <IconButton onClick={handleEditStationsClick}>
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </div>
                  <a className="photoLabel" style={{ marginTop: "5%" }}>
                    {" "}
                    {t("preferences")}
                  </a>
                  {/* <a
                    className="infoClientTitleCard2"
                    style={{ marginTop: "5%" }}
                  >
                    {t("preferences")}
                  </a> */}
                  <div className="row" style={{ marginTop: 5 }}>
                    {selectedCulturalActivities
                      .slice(0, 3)
                      .map((cultural, index) => (
                        <div
                          key={`cultural-${index}`}
                          className="infoClientGreenCard"
                        >
                          <a className="infoClientGreenCardText">
                            {cultural[i18next.language]}
                          </a>
                        </div>
                      ))}
                    {selectedSportsActivities
                      .slice(0, 3)
                      .map((sport, index) => (
                        <div
                          key={`sports-${index}`}
                          className="infoClientGreenCard"
                        >
                          <a className="infoClientGreenCardText">
                            {sport[i18next.language]}
                          </a>
                        </div>
                      ))}
                    <IconButton onClick={handleEditPreferencesClick}>
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </div>
                </div>
                <Dialog
                  open={editStationsDialogOpen}
                  onClose={handleCloseStationsDialog}
                >
                  <DialogTitle className="orderTitle">
                    {t("stations")}
                  </DialogTitle>
                  <DialogContent>
                    {/* Conteúdo do diálogo para editar estações */}
                    <div className="input-container">
                      <label style={{ paddingBottom: "30px", left: 0 }}>
                        {t("nearstation")}
                        <br />
                        <span style={{ fontSize: "10px" }}>
                          {t("selectmax3")}
                        </span>
                      </label>

                      <input
                        className={`input ${search && "has-text"}`}
                        type="text"
                        style={{ paddingLeft: "40px" }}
                        id="search"
                        onChange={handleSearch}
                        value={search}
                        required
                      />
                      <SearchIcon
                        className={"searchicon"}
                        style={{ color: Colors.green }}
                      ></SearchIcon>
                    </div>
                    <div className="scrollContainerCustom">
                      <div className="rowCustom">
                        {filteredStations.map((station) => (
                          <Card
                            key={station._id}
                            className={
                              selectedStations.includes(station)
                                ? "selectedCardCustom"
                                : "cardCustom"
                            }
                            onClick={() => handleStationClick(station)}
                          >
                            <CardContent
                              className={
                                selectedStations.includes(station)
                                  ? "selectedCardCustom"
                                  : "cardCustom"
                              }
                            >
                              <Typography
                                className={
                                  selectedStations.includes(station)
                                    ? "selectedTextCustom"
                                    : "textCustom"
                                }
                              >
                                {station.designation}{" "}
                              </Typography>
                            </CardContent>
                          </Card>
                        ))}
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseStationsDialog}
                      className="activateBtn"
                    >
                      {t("save")}
                    </Button>
                    <Button
                      onClick={handleCloseStationsDialog}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* Diálogo para editar preferências */}
                <Dialog
                  open={editPreferencesDialogOpen}
                  onClose={handleClosePreferencesDialog}
                >
                  <DialogTitle className="orderTitle">
                    {t("preferences")}
                  </DialogTitle>
                  <DialogContent>
                    <div className="row">
                      <Card
                        className={
                          selectedOption === "Cultural"
                            ? "selectedCardPref"
                            : "cardPref"
                        }
                        onClick={() => handleOptionClick("Cultural")}
                      >
                        <CardContent
                          className={
                            selectedOption === "Cultural"
                              ? "selectedCardPref"
                              : "cardPref"
                          }
                        >
                          <Typography
                            className={
                              selectedOption === "Cultural"
                                ? "selectedTextCard"
                                : "textCard"
                            }
                          >
                            {t("cultural")}
                          </Typography>
                        </CardContent>
                      </Card>
                      <Card
                        className={
                          selectedOption === "Sports"
                            ? "selectedCardPref"
                            : "cardPref"
                        }
                        onClick={() => handleOptionClick("Sports")}
                      >
                        <CardContent
                          className={
                            selectedOption === "Sports"
                              ? "selectedCardPref"
                              : "cardPref"
                          }
                        >
                          <Typography
                            className={
                              selectedOption === "Sports"
                                ? "selectedTextCard"
                                : "textCard"
                            }
                          >
                            {t("sports")}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                    <div
                      className="input-container"
                      style={{ marginTop: "-10px" }}
                    >
                      <input
                        className={`input ${search2 && "has-text"}`}
                        type="text"
                        style={{ paddingLeft: "40px" }}
                        id="search"
                        onChange={handleSearch2}
                        value={search2}
                        required
                      />
                      <SearchIcon
                        className={"searchicon"}
                        style={{ color: Colors.green }}
                      ></SearchIcon>
                    </div>
                    {selectedOption === "Cultural" && (
                      <div className="scrollContainerActivities">
                        <div className="rowActivities">
                          {filteredCultural.map((activity) => (
                            <Card
                              key={activity._id}
                              className={
                                selectedCulturalActivities.some(
                                  (selectedActivity) =>
                                    selectedActivity.id === activity.id
                                )
                                  ? "selectedCardStation"
                                  : "cardStation"
                              }
                              onClick={() => handleCulturalClick(activity)}
                            >
                              <CardContent
                                className={
                                  selectedCulturalActivities.some(
                                    (selectedActivity) =>
                                      selectedActivity.id === activity.id
                                  )
                                    ? "selectedCardStation"
                                    : "cardStation"
                                }
                              >
                                <Typography
                                  className={
                                    selectedCulturalActivities.some(
                                      (selectedActivity) =>
                                        selectedActivity.id === activity.id
                                    )
                                      ? "selectedTextCard"
                                      : "textCard"
                                  }
                                >
                                  {activity[i18next.language] || activity.en}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      </div>
                    )}
                    {selectedOption === "Sports" && (
                      <div className="scrollContainerActivities">
                        <div className="rowActivities">
                          {filteredSports.map((activity) => (
                            <Card
                              key={activity._id}
                              className={
                                selectedSportsActivities.some(
                                  (selectedActivity) =>
                                    selectedActivity.id === activity.id
                                )
                                  ? "selectedCardStation"
                                  : "cardStation"
                              }
                              onClick={() => handleSportsClick(activity)}
                            >
                              <CardContent
                                className={
                                  selectedSportsActivities.some(
                                    (selectedActivity) =>
                                      selectedActivity.id === activity.id
                                  )
                                    ? "selectedCardStation"
                                    : "cardStation"
                                }
                              >
                                <Typography
                                  className={
                                    selectedSportsActivities.some(
                                      (selectedActivity) =>
                                        selectedActivity.id === activity.id
                                    )
                                      ? "selectedTextCard"
                                      : "textCard"
                                  }
                                >
                                  {activity[i18next.language] || activity.en}
                                </Typography>
                              </CardContent>
                            </Card>
                          ))}
                        </div>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClosePreferencesDialog}
                      className="activateBtn"
                    >
                      {t("save")}
                    </Button>
                    <Button
                      onClick={handleClosePreferencesDialog}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("stations")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("stations")}
                  value={trainstations}
                />

                <TextField
                  fullWidth
                  disabled
                  multiline
                  rows={3}
                  InputProps={{
                    disableUnderline: true,
                    style: inputTrainStyle,
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("lasttrips")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("lasttrips")}
                  value={`Barcelos-Braga\nBraga-Viana do Castelo\nPorto-Lisboa`}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={edicao}
                  InputProps={{
                    disableUnderline: true,
                    style: inputPropsStyle,
                    readOnly: true,
                    endAdornment: !edicao && (
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: inputLabelPropsStyle,
                  }}
                  sx={{
                    "& .Mui-disabled": {
                      backgroundColor: "white !important",
                      color: "#044535 !important",
                    },
                    "& .MuiInputLabel-root ": {
                      color: "#044535 !important",
                    },
                  }}
                  label={t("preferences")}
                  variant="standard"
                  margin="normal"
                  placeholder={t("preferences")}
                  value={preferences}
                />
              </Grid>
            </Grid> */}
          </Container>
          <div className="bottomRowInfoClient">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                saveData();
              }}
            >
              {t("add")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAddPageAdmin;
