import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import EventsBtn from "../../images/clientDashboardIcons/EventsBtn.svg";
import StationsBtn from "../../images/clientDashboardIcons/StationsBtn.svg";
import PartnersBtn from "../../images/clientDashboardIcons/PartnersBtn.svg";
import bag from "../../images/clientDashboardIcons/bag.svg";
import portugal from "../../images/example/portugalfut.jpeg";
import amalia from "../../images/example/amalia.jpeg";
import viana from "../../images/example/viana.png";
import Cookies from "js-cookie";
import { createRoot } from "react-dom/client";
import { AdvancedMarker, APIProvider, Map, Marker, Pin } from "@vis.gl/react-google-maps";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import { Grid, Box, Skeleton } from "@mui/material";
import { getEvents } from "../../api/requests/Event";
import toast from "react-hot-toast";
import { getPartners } from "../../api/requests/Partner";
import { getCouncils } from "../../api/requests/Council";
import { getTrainStations } from "../../api/requests/Data";

function DashboardClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollRef = useRef(null);
  const [scrollLeftCities, setScrollLeftCities] = useState(0);
  const [name, setName] = useState(0);
  const [events, setEvents] = useState([]);
  const [partners, setPartners] = useState([]);
  const [trainstations, setTrainstations] = useState([]);
  const [council, setCouncil] = useState([]);
  const scrollRefCities = useRef(null);
  const cards = [
    {
      backgroundImage: portugal,
      day: "01",
      month: "jun",
      eventName: "Portugal x China",
    },
    {
      backgroundImage: portugal,
      day: "20",
      month: "jun",
      eventName: "Portugal x Alemanha",
    },
    {
      backgroundImage: portugal,
      day: "06",
      month: "jul",
      eventName: "Portugal x China",
    },
    {
      backgroundImage: portugal,
      day: "22",
      month: "jul",
      eventName: "Portugal x França",
    },
    {
      backgroundImage: portugal,
      day: "22",
      month: "aug",
      eventName: "Portugal x China",
    },
    {
      backgroundImage: portugal,
      day: "30",
      month: "sep",
      eventName: "Portugal x Marrocos",
    },
    {
      backgroundImage: portugal,
      day: "21",
      month: "oct",
      eventName: "Portugal x Marrocos",
    },
    {
      backgroundImage: portugal,
      day: "30",
      month: "nov",
      eventName: "Portugal x Marrocos",
    },
    {
      backgroundImage: portugal,
      day: "30",
      month: "dec",
      eventName: "Portugal x Marrocos",
    },
  ];
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleScroll = (scrollOffset) => {
    if (scrollRef.current) {
      const newScrollLeft = scrollRef.current.scrollLeft + scrollOffset;
      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      setScrollLeft(newScrollLeft);
    }
  };

  const handleScrollCities = (scrollOffset) => {
    if (scrollRefCities.current) {
      const newScrollLeftCities =
        scrollRefCities.current.scrollLeft + scrollOffset;
      scrollRefCities.current.scrollTo({
        left: newScrollLeftCities,
        behavior: "smooth",
      });
      setScrollLeftCities(newScrollLeftCities);
    }
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");
   
   
    setName(nameU);

    getPartners()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data);
          if (events != [] && partners != []) {
            setLoading(false);
          }
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });

 // Obtém as preferências e transforma em arrays
// Obtém as preferências e transforma em arrays
const sportsPreference = Cookies.get("sports_preference") ? Cookies.get("sports_preference").split(',') : [];
const culturePreference = Cookies.get("culture_preference") ? Cookies.get("culture_preference").split(',') : [];

getEvents()
  .then((res) => {
    if (res.status === 200 && res.success) {
      let fetchedEvents = res.data;

      // Função para verificar se o evento está nas preferências
      const isInPreferences = (event, preferenceArray) => {
        return preferenceArray.some(pref => event.modality.includes(pref));
      };

      // Ordena os eventos, colocando primeiro os que estão nas preferências
      const sortedEvents = fetchedEvents.sort((a, b) => {
        const inSportsA = isInPreferences(a, sportsPreference);
        const inSportsB = isInPreferences(b, sportsPreference);
        const inCultureA = isInPreferences(a, culturePreference);
        const inCultureB = isInPreferences(b, culturePreference);

        // Coloca os eventos que estão nas preferências primeiro
        if (inSportsA && !inSportsB) return -1; // A está nas preferências, B não
        if (!inSportsA && inSportsB) return 1;  // B está nas preferências, A não

        // Se ambos estão nas preferências ou ambos não estão, verifica a cultura
        if (inCultureA && !inCultureB) return -1; // A está nas preferências, B não
        if (!inCultureA && inCultureB) return 1;  // B está nas preferências, A não

        return 0; // Ambos estão na mesma situação (ambos nas preferências ou ambos não)
      });

      setEvents(sortedEvents); // Atualiza o estado com os eventos ordenados
    } else {
      toast.error(res.error);
    }
  })
  .catch((error) => {
    toast.error(t("500"));
  });


    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCouncil(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    if (!id && !token) {
      navigate("/loginclient");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
  }, []);

  return (
    <div className="background">
      <HeaderClientTransparent />
      <NavClient />
      <div className="backgroundImageDashboardClient">
        <div className="leftcenter">
          <a className="titleDashboardClient">{t("hi")}</a>
          <a className="titleDashboardClient">{name}</a>
          <a className="subtitleDashboardClient">{t("whereto")}</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
            paddingTop: "15vh",
            // marginLeft:"3.75rem"
          }}
        >
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${EventsBtn})` }}
            onClick={() => {
              navigate("/eventslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${StationsBtn})` }}
            onClick={() => {
              navigate("/stationslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${PartnersBtn})` }}
            onClick={() => {
              navigate("/partnerslist");
            }}
          ></button>
        </div>
        <div className="centerContent">
          <p className="dashboardClientTitle">{t("discoverEvents")}</p>
          <div
            style={{
              width: "100%",
              flexDirection: "row",
              display: "flex",
              height: " 13.125rem",
            }}
          >
            <div className="horizontal-scroll-container">
              <div className="cards" ref={scrollRef}>
                {loading
                  ? Array.from(new Array(20)).map((_, index) => (
                      <div className="card" key={index}>
                        <Skeleton
                          variant="rectangular"
                          height="13.125rem"
                          width="9.375rem"
                          sx={{ borderRadius: "0.9375rem" }}
                        />
                      </div>
                    ))
                  : events.map((card, index) => {
                      const eventDate = new Date(card.start_date);

                      const day = eventDate.toLocaleDateString("en-US", {
                        day: "numeric",
                      });
                      const month = eventDate.toLocaleDateString("en-US", {
                        month: "short",
                      });

                      return (
                        <div
                          className="card"
                          key={index}
                          style={{
                            backgroundImage: `url(${card.poster[0]})`,
                          }}
                          onClick={() => {
                            navigate("/eventpage", {
                              state: { eventId: card._id },
                            });
                          }}
                        >
                          <div className="darkEnd">
                            <div className="event-info">
                              <div className="event-date">
                                <div className="event-day">{day}</div>
                                <div className="event-month">{month}</div>
                              </div>
                              <div className="event-name">{card.name}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <button
                className="scroll-button left"
                onClick={() => handleScroll(-100)}
              >
                ←
              </button>
              <button
                className="scroll-button right"
                onClick={() => handleScroll(100)}
              >
                →
              </button>
            </div>
            <div className="mapStyle">
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height="100%"
                  width="100%"
                  sx={{ borderRadius: "0.9375rem" }}
                />
              ) : (
                <APIProvider apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}>
                  <Map
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    mapId="17c9b3c0488e85ae"
                    defaultCenter={{
                      lat: 41.70311203750535,
                      lng: -8.828393507213907,
                    }}
                    defaultZoom={12}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  >
                    {trainstations.map((station, index) => (
                      <AdvancedMarker
                        key={index}
                        position={{
                          lat: station.latitude,
                          lng: station.longitude,
                        }}
                        

                        title={station.parish}
                      
                      > <Pin
                      background={'#fd7000'}
                      borderColor={'#D56102'}
                      glyphColor={'#D56102'}
                    /></AdvancedMarker>
                    ))}
                  </Map>
                </APIProvider>
              )}
            </div>
          </div>
          <div className="gallery-container">
            <div className="gallery-page">
              {loading ? (
                Array.from(new Array(2)).map((_, index) => (
                  <Skeleton
                    variant="rectangular"
                    height="5rem"
                    width="30.7125rem"
                    sx={{ borderRadius: "0.9375rem", marginInline: 1 }}
                  />
                ))
              ) : currentPage === 1 ? (
                <>
                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers")}</a>
                      <a className="offersText2">{t("discover")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>

                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers")}</a>
                      <a className="offersText2">{t("discover")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
              {currentPage === 2 ? (
                <>
                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers3")}</a>
                      <a className="offersText2">{t("discover3")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>

                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers2")}</a>
                      <a className="offersText2">{t("discover2")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
              {currentPage === 3 ? (
                <>
                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers2")}</a>
                      <a className="offersText2">{t("discover2")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>

                  <div className="cardOffers">
                    <div
                      style={{
                        backgroundImage: `url(${bag})`,
                        width: "52px",
                        height: "64px",
                        margin: 8,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <a className="offersText">{t("offers3")}</a>
                      <a className="offersText2">{t("discover3")}</a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonGreen"
                        onClick={() => navigate("/offerpageclient")}
                      >
                        →
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="navigation">
              {[1, 2, 3].map((pageNumber) => (
                <div
                  key={pageNumber}
                  className={
                    pageNumber === currentPage ? "nav-ball active" : "nav-ball"
                  }
                  onClick={() => handlePageChange(pageNumber)}
                ></div>
              ))}
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
              height: "22vh",
            }}
          >
            <div
              style={{
                width: "720px",
                flexDirection: "column",
                display: "flex",
              }}
            >
              <p className="dashboardClientTitle">{t("nextDestination")}</p>
              <div className="horizontal-scroll-container-city">
                <div className="cardscity" ref={scrollRefCities}>
                  {loading
                    ? Array.from(new Array(6)).map((_, index) => (
                        <div className="city" key={index}>
                          <Skeleton
                            variant="rectangular"
                            height="7.1125rem"
                            width="13.125rem"
                            sx={{
                              borderRadius: "0.9375rem",
                            }}
                          />
                        </div>
                      ))
                    : council.map((card, index) => (
                        <div
                          className="city"
                          key={index}
                          style={{
                            backgroundImage: `url(${card.photo})`,
                          }}
                        >
                          <div className="darkEndCity">
                            <div
                              className="city-info"
                              onClick={() => {
                                navigate("/ticketsclient");
                              }}
                            >
                              <div className="city-name">{card.name}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                <button
                  className="scroll-button-city left"
                  onClick={() => handleScrollCities(-100)}
                >
                  ←
                </button>
                <button
                  className="scroll-button-city right"
                  onClick={() => handleScrollCities(100)}
                >
                  →
                </button>
              </div>
            </div>
            <div className="nearbyDiv">
              <a className="nearbyText"> {t("nearby")}</a>
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    height="4.8125rem"
                    width="21.3125rem"
                    sx={{ borderRadius: "0.9375rem", margin: "0.3125rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="4.8125rem"
                    width="21.3125rem"
                    sx={{ borderRadius: "0.9375rem", margin: "0.3125rem" }}
                  />
                </>
              ) : (
                <>
                  <div className="nearbyCard"
                   onClick={() =>
                    navigate("/partnerpage", {
                      state: { partnerId: partners[0]._id },
                    })
                  }>
                    <div
                      className="nearbyImage"
                      style={{
                        backgroundImage: `url(${partners[0].photo})`,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        margin: 10,
                      }}
                    >
                      <a className="nearbyName">{partners[0].name}</a>
                      <a className="nearbyType">
                        {partners[0].principal_service_type}
                      </a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonDarkGreen"
                        onClick={() => {
                          navigate("/partnerpage");
                        }}
                      >
                        →
                      </button>
                    </div>
                  </div>
                  <div className="nearbyCard"
                  onClick={() =>
                    navigate("/partnerpage", {
                      state: { partnerId: partners[1]._id },
                    })
                  }>
                    <div
                      className="nearbyImage"
                      style={{
                        backgroundImage: `url(${partners[1].photo})`,
                      }}
                    ></div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        margin: 10,
                      }}
                    >
                      <a className="nearbyName">{partners[1].name}</a>
                      <a className="nearbyType">
                        {partners[1].principal_service_type}
                      </a>
                    </div>
                    <div className="rightEnd">
                      <button
                        className="buttonDarkGreen"
                        onClick={() => {
                          navigate("/partnerpage");
                        }}
                      >
                        →
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="bottomEnd">
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/ticketsclient");
              }}
            >
              {t("newTrip")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardClient;
