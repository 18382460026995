import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import bcrypt from "bcryptjs";
import {
  getSaltAdmin,
  getSaltManager,
  loginAdmin,
  loginManager,
} from "../../api/requests/Auth";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

function LoginManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function goBack() {
    navigate("/");
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePwd = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function login() {
    // Continue with the login process if the email is valid
    if (!email || !password) {
      toast.dismiss();
      toast.error(t("emailPasswordRequired"));
      return;
    }

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is in a valid format
    if (!emailRegex.test(email)) {
      toast.error(t("invalidEmailFormat"));
      return;
    }

    //Get salt -Manager
    getSaltManager({ email: email })
      .then(async (res) => {
        if (res.status == 200 && res.success) {
          let salt = res.data.salt;
          const hashedPassword = await bcrypt.hash(password, salt);

          loginManager({ email: email, password: hashedPassword })
            .then((res2) => {
              if (res2.status === 200 && res2.success) {
                Cookies.set("id", res2.data.id);
                Cookies.set("role", "Manager");
                Cookies.set("email", res2.data.email);
                Cookies.set("token", res2.data.token);
                Cookies.set("first_name", res2.data.first_name);
                Cookies.set("last_name", res2.data.last_name);
                localStorage.setItem("photo", res2.data.photo);

                toast.success(t("loginSuccessful"));
                navigate("/dashboardmanager");
              } else {
                toast.error(res2.error);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  return (
    <div
      className="background"
      onKeyDown={(e) => (e.key === "Enter" ? login() : null)}
    >
      <div className="backgroundImage">
        <div className="topcenter">
          <div className="logo"></div>
          <p className="title">{t("manager")}</p>
        </div>
      </div>
      <div className="bottomcenter">
        {/* <div className="topleftrel">
          <IconButton onClick={goBack}>
            <ArrowBackIcon size={"small"} />
          </IconButton>
        </div> */}
        <div className="input-containerblack">
          <input
            className={`input ${email && "has-text"}`}
            type="email"
            id="email"
            onChange={handleEmail}
            value={email}
            required
          />
          <label className={!email && "visible"}>{t("email")}</label>
        </div>

        <div className="input-containerblack">
          <input
            className={`input ${password && "has-text"}`}
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={handlePwd}
            value={password}
            required
          />
          <label htmlFor="password" className={!password && "visible"}>
            {t("password")}
          </label>
          <button className="toggle-password" onClick={toggleShowPassword}>
            {showPassword ? (
              <Visibility size={"small"} />
            ) : (
              <VisibilityOff size={"small"} />
            )}
          </button>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <button type="submit" className="blackbtn">
            {t("getin")}
          </button>
        </form>
        <Link className="textlinklogin2" to={"/askresetpasswordmanager"}>
          {t("recoverPassword")}
        </Link>
      </div>
    </div>
  );
}

export default LoginManager;
