import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { DialogTitle, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import i18next from "i18next";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Search1 from "../../components/Search1";
import { getOrders } from "../../api/requests/Orders";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getStatus } from "../../api/requests/Status";
import CloseIcon from "@mui/icons-material/Close";

function OrdersAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardadmin");
  }
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });
  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginadmin");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getOrders()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setOrders(res.data);
          setOrdersCopy(res.data);
          setLoading(false)
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getStatus()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setStatus(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [ordersCopy, setOrdersCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [pending, setPending] = useState("");
  const [approved, setApproved] = useState("");
  const StateCell = ({ value }) => {
    const isApproved = value.status == "2";
    const isPending = value.status == "1";
  
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          height: "100%",
        }}
      >
        <a
          style={{
            backgroundColor: isApproved
              ? "#13BB56"
              : isPending
              ? "#0f111199"
              : "#ED4F2C",
            color: "#FFF",
            borderRadius: "5px",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "clamp(10px, 1.5vw, 16px)",
            width: "clamp(60px, 20vw, 120px)",
            height: "clamp(30px, 5vw, 40px)",
          }}
        >
          {status.find((element) => element.id === value.status)?.[
            i18next.language
          ] || ""}
        </a>
      </div>
    );
  };


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    owner_name: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      owner_name: !isSmallScreen,
    });
  }, [isSmallScreen]);
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop: "-50px",
          marginBottom: "30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)"
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)"
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayOrders = ordersCopy;
      arrayOrders = arrayOrders.filter(
        (element) =>
          // Search for
          element.owner_name.toLowerCase().includes(search.toLowerCase()) ||
          element.obs.toLowerCase().includes(search.toLowerCase())
      );
      setOrders(arrayOrders);
    } else {
      setOrders(ordersCopy);
    }
  }, [search]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleConfirmation = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const confirmOrder = () => {
    setOpenDialog(false);
  };

  return (
    <div className="background">
      <Header />
      <NavAdmin />
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("orders")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            {/* <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {}}
            ></span> */}
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                autoPageSize={true}
                columnVisibilityModel={columnVisibilityModel}
                loading={loading}
                columns={[
                  // {
                  //   field: "id",
                  //   headerName: t("id"),
                  //   type: "number",
                  //   maxWidth: 70,
                  //   flex: 1,
                  // },
                  {
                    field: "owner_name",
                    headerName: t("name"),
                    type: "string",
                    flex: 1,
                  },
                  {
                    field: "obs",
                    headerName: t("order"),
                    type: "string",
                    flex: 1,
                  },

                  {
                    field: "state",
                    headerName: t("state"),
                    flex: 1,
                    type: "string",
                    renderCell: (params) => <StateCell value={params.row} />,
                  },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 1,
                    cellClassName: "actions",
                    getActions: (params) => {
                      const { id } = params;
                      const status = params.row.status;

                      const isPending = status == "1" ? true : false;
                      const isApproved = status == "2" ? true : false;

                      const handleActionClick = () => {
                        if (isPending) {
                          setPending(true);
                        } else {
                          setPending(false);
                        }
                        if (isApproved) {
                          setApproved(true);
                        } else {
                          setApproved(false);
                        }
                        handleConfirmation();
                      };

                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{ fontSize: "clamp(12px, 1.5vw, 16px)", color: "#0F1111" }}
                              icon={faMagnifyingGlass}
                            />
                          }
                          onClick={handleActionClick}
                          label="View Details"
                          key={id}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                }}
                rows={orders}
                checkboxSelection={true}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                //Idioma
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="clientInfoEditButton"
                onClick={() => {
                  // navigate("/equipmentcreatemanager");
                }}
              >
                {t("addOrder")}
              </button>
            </div>
          </div>
          <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            PaperProps={{ style: { borderRadius: 15 } }}
          >
            <IconButton
              onClick={handleDialogClose}
              className="order-close-button"
            >
              <CloseIcon style={{ color: "black", borderRadius: 20 }} />
            </IconButton>
            <DialogTitle className="orderTitle">{t("order")}</DialogTitle>
            <DialogContent
              style={{
                borderRadius: "15px !important",
                background: "#FFF",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <DialogContentText
                style={{
                  borderRadius: "15px",
                  background: "rgba(15, 17, 17, 0.05)",
                  padding: "10px",
                }}
              >
                <p className="orderName">
                  {" "}
                  Camara Municipal de Viana do Castelo
                </p>
                <a className="orderText">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which
                </a>
              </DialogContentText>
              {pending == true ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <button onClick={handleDialogClose} className="aproveBtn">
                    {t("aprove")}
                  </button>
                  <button onClick={confirmOrder} className="cancelRedBtn">
                    {t("cancel")}
                  </button>
                </div>
              ) : (
                <>
                  {approved == true ? (
                    <button className="aprovedBtn">{t("aproved")}</button>
                  ) : (
                    <button className="canceledRedBtn">{t("canceled")}</button>
                  )}
                </>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default OrdersAdmin;
