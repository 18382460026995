import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router";
import homeIcon from "../images/navIcons/home60.svg";
import homeIconWhite from "../images/navIcons/home100.svg";
import peopleIcon from "../images/navIcons/people60.svg";
import peopleIconWhite from "../images/navIcons/people100.svg";
import partyIcon from "../images/navIcons/party60.svg";
import partyIconWhite from "../images/navIcons/party100.svg";
import stadiumIcon from "../images/navIcons/stadium60.svg";
import stadiumIconWhite from "../images/navIcons/stadium100.svg";
import handsIcon from "../images/navIcons/hands60.svg";
import handsIconWhite from "../images/navIcons/hands100.svg";
import notesIcon from "../images/navIcons/notes60.svg";
import notesIconWhite from "../images/navIcons/notes100.svg";
import cupIcon from "../images/navIcons/cup60.svg";
import cupIconWhite from "../images/navIcons/cup100.svg";
import buildingIcon from "../images/navIcons/building60.svg";
import buildingIconWhite from "../images/navIcons/building100.svg";
import bagIcon from "../images/navIcons/bag60.svg";
import bagIconWhite from "../images/navIcons/bag100.svg";
import statsIcon from "../images/navIcons/stats60.svg";
import statsIconWhite from "../images/navIcons/stats100.svg";
import bookIcon from "../images/navIcons/book60.svg";
import bookIconWhite from "../images/navIcons/book100.svg";
import walletIcon from "../images/navIcons/wallet60.svg";
import walletIconWhite from "../images/navIcons/wallet100.svg";
import helpIcon from "../images/navIcons/help60.svg";
import helpIconWhite from "../images/navIcons/help100.svg";

function NavAdmin() {
  const navigate = useNavigate();

  function getPageIndex(route) {
    switch (route) {
      case "/dashboardadmin":
        return 0;
      case "/clientsadmin":
        return 1;
      case "/eventsadmin":
        return 2;
      case "/equipmentsadmin":
        return 3;
      case "/partnersadmin":
        return 4;
      case "/paymentsadmin":
        return 5;
      case "/federationadmin":
        return 6;
      case "/counciladmin":
        return 7;
      case "/promoteradmin":
        return 8;
      case "/statsadmin":
        return 9;
      case "/ordersadmin":
        return 10;
      case "/partneroffersadmin":
        return 11;
      case "/helpadmin":
        return 12;

      default:
        return 12;
    }
  }

  const value = getPageIndex(window.location.pathname);

  const handleChange = (event, newValue) => {
    if (newValue == 0) {
      navigate("/dashboardadmin");
    } else if (newValue == 1) {
      navigate("/clientsadmin");
    } else if (newValue == 2) {
      navigate("/eventsadmin");
    } else if (newValue == 3) {
      navigate("/equipmentsadmin");
    } else if (newValue == 4) {
      navigate("/partnersadmin");
    } else if (newValue == 5) {
      navigate("/paymentsadmin");
    } else if (newValue == 6) {
      navigate("/federationadmin");
    } else if (newValue == 7) {
      navigate("/counciladmin");
    } else if (newValue == 8) {
      navigate("/promoteradmin");
    } else if (newValue == 9) {
      navigate("/statsadmin");
    } else if (newValue == 10) {
      navigate("/ordersadmin");
    } else if (newValue == 11) {
      navigate("/partneroffersadmin");
    } else if (newValue == 12) {
      navigate("/helpadmin");
    }
  };

  return (
    <>
      <div className="navbar">
        <span
          className="logonav"
          onClick={() => {
            navigate("/dashboardadmin");
          }}
        ></span>
        <div style={{ position: "absolute", marginLeft: "-10px" }}>
          <BottomNavigation
            disableRipple
            value={value}
            style={{
              backgroundColor: "transparent",
              flexDirection: "column",
              position: "absolute",
            }}
            onChange={handleChange}
            showLabels
          >
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 28,
                    height: 30,
                    backgroundImage:
                      value != 0 ? `url(${homeIcon})` : `url(${homeIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 1
                        ? `url(${peopleIcon})`
                        : `url(${peopleIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 26,
                    backgroundImage:
                      value != 2
                        ? `url(${partyIcon})`
                        : `url(${partyIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 3
                        ? `url(${stadiumIcon})`
                        : `url(${stadiumIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 28,
                    backgroundImage:
                      value != 4
                        ? `url(${handsIcon})`
                        : `url(${handsIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 5
                        ? `url(${notesIcon})`
                        : `url(${notesIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 6 ? `url(${cupIcon})` : `url(${cupIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 28,
                    height: 30,
                    backgroundImage:
                      value != 7
                        ? `url(${buildingIcon})`
                        : `url(${buildingIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 28,
                    backgroundImage:
                      value != 8 ? `url(${bagIcon})` : `url(${bagIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 9
                        ? `url(${statsIcon})`
                        : `url(${statsIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 21,
                    backgroundImage:
                      value != 10
                        ? `url(${bookIcon})`
                        : `url(${bookIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />
            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 30,
                    height: 30,
                    backgroundImage:
                      value != 11
                        ? `url(${walletIcon})`
                        : `url(${walletIconWhite})`,
                  }}
                />
              }
              classes={{
                root: "bottomNavigationAction",
              }}
            />

            <BottomNavigationAction
              disableRipple
              icon={
                <span
                  style={{
                    width: 22,
                    height: 22,
                    backgroundImage:
                      value != 12
                        ? `url(${helpIcon})`
                        : `url(${helpIconWhite})`,
                  }}
                />
              }
              style={{
                position: "fixed",
                bottom: 10,
              }}
            />
          </BottomNavigation>
        </div>
      </div>
    </>
  );
}

export default NavAdmin;
