import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header.js";
import NavAdmin from "../../components/navAdmin.js";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/feira.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import { postEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";
import NavManager from "../../components/navManager.js";

function EquipmentCreateManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();

  function goBack() {
    navigate("/equipmentsmanager");
  }
  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const trainStations = ["Lisboa Santa Apolónia", "Porto Campanhã", "Faro"];
  const preferences = [
    { id: 1, designation: "atletismo" },
    { id: 2, designation: "futebol" },
  ];

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: "60%",
  };

  const handleAllowRegisterChange = (equipment) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (equipment) => {
    setAudience(!audience);
  };

  const handlePromoterChange = (equipment) => {
    setPromoterId(equipment.target.value);
  };

  const handleCouncilChange = (equipment) => {
    setCouncilId(equipment.target.value);
  };

  const handleManagerChange = (equipment) => {
    setManagerId(equipment.target.value);
  };

  const today = dayjs();

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState("");
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState([]);

  const [equipmentUrl, setEquipmentUrl] = useState("");
  const [free, setFree] = useState("");
  const [gallery, setGallery] = useState([]);

  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [obs, setObs] = useState("");
  const [promoterId, setPromoterId] = useState("");
  const [managerId, setManagerId] = useState("");

  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(Date);
  const [modality, setModality] = useState("0");
  const [modalities, setModalities] = useState([]);
  const [tourLink, setTourLink] = useState("");

  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [video, setVideo] = useState([]);
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [managers, setManagers] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [photo, setPhoto] = useState("");
  const [avatarData, setAvatarData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };
  const handleModalitiesChange = (event, newValue) => {
    setModalities(newValue);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  const [preview, setPreview] = useState("");
  const [preview1, setPreview1] = useState("");
  const [preview2, setPreview2] = useState("");
  const [preview3, setPreview3] = useState("");
  const [preview4, setPreview4] = useState("");

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  useEffect(() => {
    const avatar = {
      sx: {
        bgcolor: "#11bb55",
      },
      children: `Teste`,
    };
    setAvatarData(avatar);
  }, []);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileUrl, setFileUrl] = useState(null);

  const onDropFile = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFile(file);

    // Determine o tipo do arquivo
    const fileType = file.type.includes("pdf") ? "pdf" : "image";
    setFileType(fileType);
  };
  // useEffect(() => {
  //   if (file) {
  //     setFileUrl(URL.createObjectURL(file));
  //   }
  //   return () => {
  //     if (fileUrl) {
  //       URL.revokeObjectURL(fileUrl);
  //     }
  //   };
  // }, [file]);
  const dropzoneStyle = {
    border: "2px dashed #0087F7",
    borderRadius: "5px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    width: "60%",
  };

  const imageStyle = {
    maxWidth: "60%",
    height: "100px",
  };

  return (
    <div className="background">
      <HeaderManagerWhite />
      <NavManager />
      <div className="centerpage">
        <div className="topLeftBack">
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              size={"small"}
              style={{ fill: "#044535", zIndex: 99 }}
            />
          </IconButton>
        </div>
        <a className="greenTitle">{t("addEquipment")}</a>
        <div className="whiteBoard">
          <div className="scrollableContentEvent">
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                <div className="photosEvent">
                  <div className="photoColumn">
                    <a className="photoLabel">{t("mainPhoto")}*</a>
                    <Dropzone onDrop={onDrop} accept="image/*" multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div className="addPoster" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {!preview ? (
                              <div className="addPhoto">
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "rgba(15, 17, 17, 0.60)",
                                  }}
                                  icon={faPlus}
                                />
                              </div>
                            ) : (
                              <div className="addPosterEdit">
                                <img src={preview} className="addPoster" />
                                <div className="overlay">
                                  <FontAwesomeIcon
                                    className="hoverIcon"
                                    icon={faPen}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>

                  <div className="morePhotos">
                    <a className="photoLabel">{t("photosvideos")}</a>
                    <div className="photoRow">
                      <>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview1(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop1}
                            disabled={preview1 ? true : false}
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview1 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview1}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview2(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop2}
                            disabled={preview2 ? true : false}
                            accept="image/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview2 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview2}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview3(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop3}
                            accept="image/*"
                            disabled={preview3 ? true : false}
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview3 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview3}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setPreview4(null);
                          }}
                        >
                          <Dropzone
                            onDrop={onDrop4}
                            accept="image/*"
                            multiple={false}
                            disabled={preview4 ? true : false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div className="addPoster" {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  {!preview4 ? (
                                    <div
                                      className="addPhoto"
                                      style={{ marginRight: "10px" }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: "16px",
                                          color: "rgba(15, 17, 17, 0.60)",
                                        }}
                                        icon={faPlus}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="addPosterEdit"
                                      style={{
                                        marginRight: "10px !important",
                                      }}
                                    >
                                      <img
                                        src={preview4}
                                        className="addPoster"
                                      />
                                      <div className="overlay">
                                        <FontAwesomeIcon
                                          className="hoverIcon"
                                          icon={faTrashCan}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("name")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("contact")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setContact(e.target.value);
                      }}
                      value={contact}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("free")}?</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={free}
                      onChange={(e) => {
                        setFree(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("capacity")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      type="number"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={audienceCapacity}
                      onChange={(e) => {
                        setAudienceCapacity(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("description")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={80}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      multiline={true}
                      rows={4}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      onChange={(e) => {
                        setObs(e.target.value);
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={obs}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={typeId}
                      onChange={handleChangeType}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type.id}>
                          {type[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("modalities")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      multiple
                      options={
                        typeId === "1" ? sportsActivities : culturalActivities
                      }
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={Array.isArray(modalities) ? modalities : []}
                      onChange={handleModalitiesChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                          placeholder={t("modality")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("schedule")}</a>

                  <Dropzone
                    onDrop={onDropFile}
                    accept="image/*"
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          border: "2px dashed #cccccc",
                          borderRadius: "10px",
                          padding: "20px",
                          textAlign: "center",
                          color: "#666666",
                          backgroundColor: "#f9f9f9",
                          cursor: "pointer",
                          transition: "background-color 0.3s ease",
                          width: "54%",
                        }}
                      >
                        <input {...getInputProps()} />
                        <p>{t("selectImg")}</p>
                      </div>
                    )}
                  </Dropzone>

                  {file && fileType === "image" && (
                    <div>
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        style={imageStyle}
                      />
                    </div>
                  )}
                  {/* {file && fileType === "pdf" && (
                    <div>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={fileUrl}
                          plugins={[defaultLayoutPluginInstance]}
                        />
                      </Worker>
                    </div>
                  )} */}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("promoter")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={promoterId}
                      onChange={handlePromoterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {promoters.map((promoter) => (
                        <MenuItem key={promoter._id} value={promoter._id}>
                          {promoter.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("manager")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={managerId}
                      onChange={handleManagerChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {managers.map((manager) => (
                        <MenuItem key={manager._id} value={manager._id}>
                          {manager.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("cityCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={councilId}
                      onChange={handleCouncilChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {cityCouncils.map((council) => (
                        <MenuItem key={council._id} value={council._id}>
                          {council.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                ></div>
                <a className="photoLabel">{t("district")}</a>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width="60%"
                    height={40}
                    sx={{ borderRadius: "0.9375rem" }}
                  />
                ) : (
                  <Autocomplete
                    options={districts}
                    getOptionLabel={(option) =>
                      option[i18next.language] || option.en
                    }
                    value={district}
                    onChange={handleDistrictChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...inputPropsStyle,
                            textAlign: "left",
                            borderRadius: "8px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: inputLabelPropsStyle,
                        }}
                        sx={{
                          "& .Mui-disabled": {
                            backgroundColor: "white !important",
                            color: "#044535 !important",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#044535 !important",
                          },
                        }}
                        variant="standard"
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option[i18next.language] || option.en}
                      </li>
                    )}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        display: "none",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: 0,
                      },
                      "& .MuiAutocomplete-listbox": {
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                      },
                      "& .MuiAutocomplete-inputRoot": {
                        padding: "0 !important",
                      },
                    }}
                  />
                )}
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLink")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEquipmentUrl(e.target.value);
                      }}
                      value={equipmentUrl}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("tourLink")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setTourLink(e.target.value);
                      }}
                      value={tourLink}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentYoutube")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentLinkedin")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipmentX")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="bottomRowInfoClient">
            <button className="clientInfoEditButton" onClick={() => {}}>
              {t("create")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentCreateManager;
